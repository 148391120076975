import styled from 'styled-components';

import { HeaderGradient, Text, Title } from '../../styles/global';

interface Props {
  readonly centralizado?: 'true';
}

const HeaderContainer = styled(HeaderGradient)`
  padding: var(--espacamento-sm);
`;

export const HeaderWrapper = styled.div<Props>`
  display: grid;
  grid-template-columns: 45px 1fr;
  grid-template-rows: ${(props) => (props.centralizado ? 'minmax(35px, auto) 1fr 1fr' : 'minmax(35px, auto) auto')};
  justify-items: stretch;
  align-items: center;

  max-width: 600px;
  margin: 0 auto;
`;

export const HeaderTitle = styled(Title)<Props>`
  ${(props) => props.centralizado && `
    grid-row: 2;
    grid-column: span 2;

    text-align: center;
  `}
`;

export const HeaderText = styled(Text)`
  && {
    grid-row: 3;
    grid-column: span 2;

    text-align: center;
  }
`;

export const Breadcrumb = styled.span`
  grid-column: 2;

  display: flex;
  align-items: center;

  font-size: 2rem;
  font-weight: 300;
  color: #ffffffd9;

  &::before {
    content: "";

    width: 5px;
    height: 5px;
    margin-right: 8px;

    background: #fff;
    border-radius: 100%;
  }
`;

export default HeaderContainer;

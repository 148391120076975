/**
 * @summary Recebe o número do celular e retorna o número na formatação da pagarme
 * @param {string} numero - e.g: 99 9999 9999 (apenas 0-9 digitos)
 * @return {string} formato pagarme - e.g: +559999999999
 */
export default function formataCelularParaPagarme(numero: string) {
  let pagarMeCellphone = numero;

  while (pagarMeCellphone.includes(' ')) {
    pagarMeCellphone = pagarMeCellphone.replace(' ', '');
  }

  pagarMeCellphone = pagarMeCellphone.replace('(', '').replace(')', '');

  pagarMeCellphone = pagarMeCellphone.replace('-', '');

  return `+55${pagarMeCellphone}`;
}

import styled from 'styled-components';

import { FormControl, TextField, InputAdornment } from '@material-ui/core';

export const Cifrao = styled(InputAdornment)`
  && p {
    font-size: 3rem;
  }
`;

export const Centavos = styled(InputAdornment)`
  && p {
    font-size: 3.5rem;
  }
`;

export const MuiInput = styled(TextField)`
  && {
    input {
      text-align: right;

      font-family: 'Open Sans', sans-serif;
      font-size: 5rem;
    }
  }
`;

type MuiFormType = {
  error?: boolean;
}

const MuiFormControl = styled(FormControl)<MuiFormType>`
  && {
    margin: var(--espacamento-xlg) 0;
    width: 60%;

    .MuiInput-underline:before {
      border-bottom: 2px solid;
      border-color: ${(props) => (props.error ? '#f44336' : props.theme.cores.secundaria)};
    }

    .MuiInput-underline:after {
      border-bottom: 3px solid;
      border-color: ${(props) => (props.error ? '#f44336' : props.theme.cores.primaria)};
    }

    .MuiFormHelperText-root {
      color: #f44336;
      font-size: 1.75rem;
    }
  }
`;

export default MuiFormControl;

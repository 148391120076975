import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';

import InputMask from 'react-input-mask';
import { FormHelperText } from '@material-ui/core';

import { InputSuffix } from '../../styles/global';

import Container, { Label, MuiInputStyled } from './styles';
import mascaras, { MascarasType } from '../../utils/mascaras';

interface MuiInputProps {
  name: string,
  label: string,
  helperText?: string | null,
  validacao?: boolean,
  valorInicial: string,
  disabled?: boolean,
  type?: string,
  gutterbottom?: boolean | 'true',
  mascara?: MascarasType;
  inputMask?: string;
  endAdornment?: string;
  onChange?: (value: string) => void;
}

interface InputProps extends Omit<MuiInputProps, 'label'> {
  manipulaInput: (input: HTMLInputElement) => void;
  inputRef: React.MutableRefObject<HTMLInputElement | undefined>;
}

function Input({
  inputMask,
  name,
  validacao,
  type,
  disabled,
  manipulaInput,
  valorInicial,
  inputRef,
}: InputProps) {
  return (
    <>
      {inputMask ? (
        <InputMask
          mask={inputMask}
          maskPlaceholder=" "
          defaultValue={valorInicial}
          onChange={(event: { target: HTMLInputElement; }) => manipulaInput(event.target)}
        >
          {(inputProps: any) => (
            <MuiInputStyled
              id={name}
              name={name}
              error={!validacao}
              inputProps={{ ref: inputRef }}
              type={type}
              disabled={disabled}
              {...inputProps}
            />
          )}
        </InputMask>
      ) : (
        <MuiInputStyled
          id={name}
          name={name}
          defaultValue={valorInicial}
          inputProps={{ ref: inputRef }}
          onChange={(event: any) => manipulaInput(event.target)}
          error={!validacao}
          type={type}
          disabled={disabled}
        />
      )}
    </>
  );
}

function MuiInput({
  name,
  label,
  validacao,
  helperText,
  valorInicial,
  disabled,
  type,
  gutterbottom,
  mascara,
  inputMask,
  endAdornment,
  onChange,
}: MuiInputProps) {
  const [posicaoCursor, setPosicaoCursor] = useState<number>(0);

  const inputRef = useRef<HTMLInputElement>();

  const manipulaInput = useCallback((input: HTMLInputElement) => {
    const { value, selectionStart } = input;

    if (inputRef && inputRef.current) {
      if (mascara) {
        setPosicaoCursor(selectionStart || 0);
        inputRef.current.value = mascaras(mascara, value);
      } else {
        inputRef.current.value = value;
      }

      if (onChange) onChange(inputRef.current.value);
    }
  }, []);

  useEffect(() => {
    if (inputRef && inputRef.current && inputRef?.current.type === 'text') {
      inputRef.current.setSelectionRange(posicaoCursor, posicaoCursor);
    }
  }, [posicaoCursor]);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = valorInicial;
    }
  }, [valorInicial]);

  return (
    <Container
      error={!validacao}
      fullWidth
      margin="dense"
      variant="standard"
      gutterbottom={gutterbottom}
    >
      <>
        <Label htmlFor={name}>{label}</Label>
        <Input
          inputMask={inputMask}
          name={name}
          validacao={validacao}
          valorInicial={valorInicial}
          type={type}
          inputRef={inputRef}
          disabled={disabled}
          manipulaInput={manipulaInput}
        />
        {endAdornment && <InputSuffix>{endAdornment}</InputSuffix>}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </>
    </Container>
  );
}

export default MuiInput;

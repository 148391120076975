import React, { memo } from 'react';

import SelectButton from '../../../../../../components/SelectButton';

import { Title, Text } from '../../../../../../styles/global';

import SelectButtonContributeContainer, { ArrowForwardIcon, RecomendadoStyled } from './styles';

interface ButtonContributeProps {
  titulo?: string;
  texto?: string;
  recomendado?: boolean;
  onClick: () => void;
}

function ButtonToContribue({
  titulo,
  texto,
  recomendado,
  onClick,
}: ButtonContributeProps) {
  return (
    <SelectButton destaque={recomendado} gutterBottom onClick={() => onClick()}>
      <SelectButtonContributeContainer>
        <>
          <div>
            {recomendado && <RecomendadoStyled>Recomendado</RecomendadoStyled>}
            <Title size="3rem" bottomspacing="1rem">{titulo}</Title>
            <Text size="2rem" fontcolor="#444444" weight="400">
              {texto}
            </Text>
          </div>
          <ArrowForwardIcon />
        </>
      </SelectButtonContributeContainer>
    </SelectButton>
  );
}

export default memo(ButtonToContribue);

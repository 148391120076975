/**
 * Retorna enviroment
 * @returns {string} - Ambiente
 */
export default function getEnviroment(): string {
  const path = window.location.href;

  if (path.includes('develop')) {
    return 'develop';
  }
  if (path.includes('release')) {
    return 'release';
  }
  if (path.includes('hotfix')) {
    return 'hotfix';
  }
  if (path.includes('production') || path.includes('contribua.libcom.org.br')) {
    return 'production';
  }

  return 'local';
}

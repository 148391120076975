import React, { ChangeEvent, memo } from 'react';

import { Checkbox } from '@material-ui/core';

import { Text } from '../../styles/global';

import CheckboxContainer from './styles';

interface MuiCheckboxProps {
  label?: string,
  checado: boolean,
  onChange: (evento: ChangeEvent<HTMLInputElement>) => void
}
function MuiCheckbox({ label, checado, onChange }: MuiCheckboxProps) {
  return (
    <CheckboxContainer
      label={
        <Text size="1.8rem" weight="400">{label}</Text>
      }
      control={(
        <Checkbox
          color="primary"
          value={checado}
          checked={String(checado) === 'true'}
          onChange={onChange}
        />
      )}
    />
  );
}

export default memo(MuiCheckbox);

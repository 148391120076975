import React, { memo } from 'react';

import {
  Phone as PhoneIcon,
  MailOutline as MailOutlineIcon,
  WhatsApp as WhatsAppIcon,
} from '@material-ui/icons';

import SelectButton from '../SelectButton';
import { Text } from '../../styles/global';

import BotoesAcao from './styles';

function ModalHelpContent() {
  return (
    <>
      <Text size="2rem" weight="600" fontcolor="#444444">Horário de atendimento</Text>
      <Text size="2rem" fontcolor="#444444">Segunda a sexta das 09h às 18h</Text>
      <BotoesAcao>
        <SelectButton destaque href="tel:+55115081-3732" icone={<PhoneIcon />}>
          (11) 5081-3732
        </SelectButton>
        <SelectButton destaque href="mailto:cooperador@libcom.org.br" icone={<MailOutlineIcon />}>
          cooperador@libcom.org.br
        </SelectButton>
        <SelectButton destaque href="https://api.whatsapp.com/send?phone=5511974720876" icone={<WhatsAppIcon />}>
          Enviar Mensagem
        </SelectButton>
      </BotoesAcao>
    </>
  );
}

export default memo(ModalHelpContent);

import React, { ReactChild } from 'react';

import Modal from '../../components/Modal';
import ModalHelpContent from '../../components/ModalHelpContent';

import { ReactComponent as IconeSuporte } from '../../statics/img/icone-suporte.svg';
import { Container, Title } from '../../styles/global';

import IconButtonContainer from './styles';
import useModal from '../../utils/hooks/useModal';

interface HelpProps {
  children?: ReactChild;
}

/**
 * @returns Cabeçalho da Modal
 */
function ModalHelpHeader() {
  return (
    <>
      <Title size="2.5rem" fontcolor="#FFFFFF">Fale Conosco</Title>
    </>
  );
}

function HelpContainer({ children }: HelpProps) {
  const [abre, toggle] = useModal();

  return (
    <Container horizontalCenter verticalCenter>
      {children}
      <IconButtonContainer onClick={() => toggle()}>
        <IconeSuporte />
      </IconButtonContainer>
      <Modal
        exibe={abre}
        toggle={toggle}
        cabecalho={<ModalHelpHeader />}
        conteudo={<ModalHelpContent />}
      />
    </Container>
  );
}

export default HelpContainer;

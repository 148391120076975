import axios, { Method } from 'axios';

import getUrlApi from './url';

interface IPromiseApiProps {
  apiName: string;
  metodo: Method;
  url: string;
  body?: string | object;
}

const api = axios.create();

/**
 * Retorna uma Promise
 * @param {IPromiseApiProps} param - parametros para realizar uma chamada axios
 * @returns {Promise<void>}
 */
function returnPromiseApi({
  apiName,
  metodo,
  url,
  body,
}: IPromiseApiProps): Promise<void> {
  const resposta = api({
    method: metodo,
    url: getUrlApi(apiName) + url,
    data: JSON.stringify(body),
  });

  return resposta.then((data) => {
    console.log('success: ', data);
  });
}

export default returnPromiseApi;

import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  memo,
} from 'react';

import { AppContext } from '../../../../contexts/AppContext';
import { DadosCadastraisContext } from '../../../../contexts/DadosCadastraisContext';

import MuiInput from '../../../../components/MuiInput';
import MuiButton from '../../../../components/MuiButton';

import useRouter from '../../../../utils/hooks/useRouter';

function Nome() {
  const router = useRouter();
  const { appState, appDispatch } = useContext(AppContext);
  const { dadosCadastrais, dadosCadastraisDispatch } = useContext(DadosCadastraisContext);
  const [valido, setValido] = useState<boolean>(true);

  const { nome } = dadosCadastrais.dados;

  useEffect(() => {
    appDispatch({ type: 'MANIPULA_DISABLED', payload: !valido });
  }, [valido]);

  /**
   * Valida se o nome digitado está correto
   * @param {string} nome - Nome digitado pelo usuario
   * @return {boolean} Se é valido ou não o nome digitado
   */
  const validaNomeDigitado = useCallback((nomeDigitado: string): boolean => {
    const regex = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{2,}\s([A-Za-z\s]{2,3})?[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s']{4,}$/;
    const resultado = regex.test(nomeDigitado);
    setValido(resultado);

    return resultado;
  }, []);

  /**
   * Ao carregar componente atualizar a url anterior e valida campo
   */
  useEffect(() => {
    appDispatch({ type: 'ALTERA_URL_ANTERIOR', payload: '/formulario/4/dados-pessoais/cpf' });

    // Se o estado inicial for vazio não passa pela validação
    if (nome === '') {
      appDispatch({ type: 'MANIPULA_DISABLED', payload: true });
    } else {
      validaNomeDigitado(nome);
    }
  }, []);

  /**
   * Ao digitar valida o nome digitado pelo usuario, se for valido salva o valor no estado nome
   * @param {string} valor - Valor digitado pelo usuario
   */
  const manipulaInputNome = useCallback((valor: string) => {
    let nomeValido;

    if (valor) {
      nomeValido = validaNomeDigitado(valor);
    } else {
      dadosCadastraisDispatch({ type: 'ALTERA_NOME', payload: '' });
      appDispatch({ type: 'MANIPULA_DISABLED', payload: true });
      return;
    }

    appDispatch({ type: 'MANIPULA_DISABLED', payload: !nomeValido });

    if (nomeValido) {
      dadosCadastraisDispatch({ type: 'ALTERA_NOME', payload: valor });
    }
  }, []);

  return (
    <>
      <MuiInput
        name="nome"
        label="Informe seu nome completo"
        gutterbottom="true"
        validacao={valido}
        valorInicial={nome}
        onChange={manipulaInputNome}
        mascara="PRIMEIRA_LETRA_MAIUSCULA"
      />
      <MuiButton
        primary="true"
        fullWidth
        disabled={appState.disabled}
        onClick={() => router.push('/formulario/4/dados-pessoais/data-nascimento')}
      >
        Continuar
      </MuiButton>
    </>
  );
}

export default memo(Nome);

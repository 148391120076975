import styled from 'styled-components';
import {
  FormControl,
  InputLabel,
  Input,
  Select,
} from '@material-ui/core';

export const Label = styled(InputLabel)`
  && {
    font-size: 2.3rem;
  }
`;

export const MuiInputStyled = styled(Input)`
  && {
    position: relative;

    font-size: 2.3rem;
  }
`;

export const SelectStyled = styled(Select)`
  && {
    .MuiSelect-root {
      display: flex;
      align-items: center;

      font-size: 2.3rem;
    }

    .MuiSelect-icon {
      width: 4rem;
      height: 4rem;
      top: calc(50% - 16px);
      color: #000000;
    }
  }
`;

interface ContainerProps {
  readonly gutterbottom?: boolean | 'true';
}

const Container = styled(FormControl)<ContainerProps>`
  && {
    .MuiFormLabel-root.Mui-focused {
      color: ${(props) => (props.error ? '#f44336' : props.theme.cores.primaria)}
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid;
      border-color: ${(props) => (props.error ? '#f44336' : props.theme.cores.secundaria)};
    }
    .MuiInput-underline.Mui-disabled:before {
      border-bottom: 1px dotted #000000;
    }

    .MuiInput-underline:after {
      border-bottom: 3px solid;
      border-color: ${(props) => (props.error ? '#f44336' : props.theme.cores.primaria)};
    }
    .MuiInput-underline.Mui-disabled:after {
      border-bottom: 1px dotted #000000;
    }

    .MuiFormHelperText-root {
      color: #f44336;
      font-size: 1.75rem;
    }

    ${(props) => (props.gutterbottom && `
      margin-bottom: 5rem;
    `)}
  }
`;

export default Container;

export default {
  nome: 'libcom',

  cores: {
    primaria: '#D7B24C',
    secundaria: '#000000',

    background: '#FFFFFF',
    backgroundDegrade: 'linear-gradient(115deg, #887334, #D5B14D)',
    texto: '#444444',
  },
};

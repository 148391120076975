import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  memo,
} from 'react';
import { useHistory } from 'react-router-dom';

import { AppContext } from '../../../../contexts/AppContext';
import { ContribuicaoContext } from '../../../../contexts/ContribuicaoContext';

import MuiFormControl, { Cifrao, MuiInput, Centavos } from './styles';
import MuiButton from '../../../../components/MuiButton';

import mascaras from '../../../../utils/mascaras';
import irParaNovaRota from '../../../../utils/irParaNovaRota';
import { Title } from '../../../../styles/global';

function OutroValor() {
  const history = useHistory();
  const { appState, appDispatch } = useContext(AppContext);
  const { contribuicao, contribuicaoDispatch } = useContext(ContribuicaoContext);
  const {
    textoMeioDePagamento,
    textoDiaDoVencimento,
    textoMesDeInicio,
  } = appState.cabecalho.breadcrumbs;

  const breadcrumb = {
    textoMeioDePagamento,
    textoMesDeInicio,
    textoDiaDoVencimento,
    textoValorDaContribuicao: '',
  };

  const [valorInput, setValorInput] = useState(String);
  const [error, setError] = useState(Boolean);
  const [helper, setHelper] = useState(String);

  /**
   * Altera o estado do input e atualiza a contribuicao;
   * o valor é formatado com uma mascara para real
   * Atualiza o cabecalho
   * É exibido erro caso seja digitado um valor menor que o minimo e maior que o máximo
   * @param {string} value - Valor digitado pelo usuário.
   */
  const manipulaInput = useCallback((value: string) => {
    let valor;
    const valorFormatadoParaInput = mascaras('MOEDA_BR', value);

    if (valorFormatadoParaInput.length > 8) {
      valor = parseFloat(valorFormatadoParaInput.replace('.', '').replace('.', ''));
    } else {
      valor = parseFloat(valorFormatadoParaInput.replace('.', ''));
    }

    const valorMinimo = contribuicao?.tipo === 2 ? 50.00 : 10.00;
    const valorMaximo = contribuicao?.meioDePagamento === 3 && 9999.00;

    if (valor < valorMinimo || valorFormatadoParaInput === '') {
      setHelper(`O valor mínimo é R$${valorMinimo},00`);
      setError(true);
      contribuicaoDispatch({ type: 'ALTERA_VALOR_CONTRIBUICAO', payload: valorMinimo });
    } else if (valorMaximo && valor > valorMaximo) {
      setHelper(`O valor máximo para boleto é R$${valorMaximo},00`);
      setError(true);
      contribuicaoDispatch({ type: 'ALTERA_VALOR_CONTRIBUICAO', payload: valorMaximo });
    } else {
      setHelper('');
      setError(false);
      appDispatch({ type: 'MANIPULA_DISABLED', payload: false });
      contribuicaoDispatch({ type: 'ALTERA_VALOR_CONTRIBUICAO', payload: valor });
    }

    setValorInput(valorFormatadoParaInput);
  }, []);

  /**
   * Ao carregar o componente carrega o valor inicial formatando para exibição
   * Altera a etapa atual
   */
  useEffect(() => {
    appDispatch({ type: 'MANIPULA_DISABLED', payload: true });
    appDispatch({ type: 'ALTERA_ETAPA_ATUAL', payload: 3 });
    appDispatch({ type: 'ALTERA_URL_ANTERIOR', payload: '/formulario/3/contribuicao/informa-valor' });

    contribuicaoDispatch({ type: 'ALTERA_VALOR_CONTRIBUICAO', payload: 0 });
  }, []);

  return (
    <>
      <Title secondary="true">
        Com qual valor pode ajudar?
      </Title>
      <MuiFormControl error={error}>
        <MuiInput
          value={valorInput}
          helperText={helper}
          onChange={(e) => manipulaInput(e.target.value)}
          InputProps={{
            startAdornment: <Cifrao position="start">R$</Cifrao>,
            endAdornment: <Centavos position="end">,00</Centavos>,
          }}
        />
      </MuiFormControl>
      <MuiButton
        disabled={error || appState.disabled}
        primary="true"
        fullWidth
        onClick={() => {
          irParaNovaRota(history, 'formulario/4/dados-pessoais/cpf');
          appDispatch({ type: 'ALTERA_URL_ANTERIOR', payload: 'formulario/3/contribuicao/outro-valor' });
          appDispatch({
            type: 'ALTERA_BREADCRUMBS',
            payload: {
              ...breadcrumb,
              textoValorDaContribuicao: `R$ ${valorInput}`,
            },
          });
        }}
      >
        Continuar
      </MuiButton>
    </>
  );
}

export default memo(OutroValor);

import CadastroCPF from './CadastroCPF';
import Nome from './Nome';
import DataNascimento from './DataNascimento';
import Celular from './Celular';
import Email from './Email';
import Endereco from './Endereco';

const rotasDadosPessoais = [
  {
    path: '/formulario/4/dados-pessoais/cpf',
    component: CadastroCPF,
  },
  {
    path: '/formulario/4/dados-pessoais/nome',
    component: Nome,
  },
  {
    path: '/formulario/4/dados-pessoais/data-nascimento',
    component: DataNascimento,
  },
  {
    path: '/formulario/4/dados-pessoais/celular',
    component: Celular,
  },
  {
    path: '/formulario/4/dados-pessoais/email',
    component: Email,
  },
  {
    path: '/formulario/4/dados-pessoais/endereco',
    component: Endereco,
  },
];

export default rotasDadosPessoais;

import React, {
  useEffect,
  useContext,
  useCallback,
  useState,
  useMemo,
} from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Snackbar } from '@material-ui/core';

import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { AppContext } from '../../contexts/AppContext';
import { DadosCadastraisContext } from '../../contexts/DadosCadastraisContext';
import { BoletoContext } from '../../contexts/BoletoContext';
import { ContribuicaoContext } from '../../contexts/ContribuicaoContext';

import GreetingsContainer from '../../components/GreetingsContainer';
import MuiButton from '../../components/MuiButton';

import {
  Title, Text,
} from '../../styles/global';

import ButtonGroup, { AlertStyled } from './styles';

function ContribuicaoConcluida() {
  const { appDispatch } = useContext(AppContext);
  const { dadosCadastraisDispatch } = useContext(DadosCadastraisContext);
  const { boletoState } = useContext(BoletoContext);
  const { contribuicao } = useContext(ContribuicaoContext);
  const [abreAlerta, setAbreAlerta] = useState<boolean>(false);

  useEffect(() => {
    appDispatch({ type: 'INICIA_CONTRIBUICAO', payload: false });
    dadosCadastraisDispatch({ type: 'RESET_DADOS' });
  }, []);

  const visualizaBoleto = useCallback(() => {
    window.open(`${boletoState.boletoURL}`, '_blank');
  }, []);

  const manipulaAberturaAlerta = useCallback(() => {
    setAbreAlerta(!abreAlerta);
  }, [abreAlerta]);

  const dataInicioFormatada = useCallback(() => {
    const data = new Date();
    if (contribuicao.dataInicio) {
      const dataInicio = new Date(
        parseInt(contribuicao.dataInicio.split('-')[0], 10),
        (parseInt(contribuicao.dataInicio.split('-')[1], 10) - 1),
        parseInt(contribuicao.dataInicio.split('-')[2], 10),
      );
      dataInicio.setDate(dataInicio.getDate() - 5);
      return dataInicio.toLocaleDateString('pt-BR');
    }
    return data.toLocaleDateString('pt-BR');
  }, []);
  return (
    <GreetingsContainer animacao temBoleto={boletoState.boletoURL}>
      <>
        <Title>Obrigado!</Title>
        <Text weight="600">
          Nos alegramos com seu desejo de contribuir conosco.
        </Text>
        <Text>
          Cada contribuição é fundamental para levarmos os ideais
          do <strong>LIBCOM</strong> para mais pessoas.
        </Text>
        {!boletoState.boletoURL && contribuicao.meioDePagamento === 3 && (
          <Text>
            Sua contribuição foi cadastrada com sucesso.&nbsp;
            Enviaremos o seu boleto de contribuição por e-mail no dia {dataInicioFormatada()}.
          </Text>
        )}
        {boletoState.boletoURL && (
          <>
            <Snackbar
              open={abreAlerta}
              autoHideDuration={3000}
              onClose={manipulaAberturaAlerta}
            >
              <AlertStyled severity="success" onClose={manipulaAberturaAlerta}>Copiado com sucesso!</AlertStyled>
            </Snackbar>
            <ButtonGroup>
              <CopyToClipboard
                text={boletoState.boletoBarcode}
                onCopy={manipulaAberturaAlerta}
              >
                <MuiButton
                  primary="true"
                  fullWidth
                  startIcon={<FileCopyOutlinedIcon />}
                >
                  COPIAR CÓD. DE BARRAS
                </MuiButton>
              </CopyToClipboard>
              <MuiButton
                fullWidth
                border
                startIcon={<InsertDriveFileIcon />}
                onClick={visualizaBoleto}
              >
                VISUALIZAR BOLETO
              </MuiButton>
            </ButtonGroup>
          </>
        )}
      </>
    </GreetingsContainer>
  );
}

export default ContribuicaoConcluida;

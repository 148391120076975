import styled, { keyframes } from 'styled-components';
import BannerPessoas from '../../statics/img/view-inicial.jpg';

import { Container } from '../../styles/global';

interface WrapperProps {
  temBoleto?: string;
}

export const GreetingsWrapper = styled(Container)<WrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  min-height: ${(props) => (props.temBoleto ? '65vh' : '45vh')};

  padding: var(--espacamento-m) var(--espacamento-lg);

  && button {
    margin-bottom: var(--espacamento-xsm);
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${BannerPessoas});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  height: 35vh;
`;

const mountFinalAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(215, 178, 76, .3),
    0 0 0 0px rgba(215, 178, 76, .5),
    0 0 0 0px rgba(215, 178, 76, .8);
  }
  100% {
    box-shadow: 0 0 0 30px rgba(215, 178, 76, .1),
    0 0 0 20px rgba(215, 178, 76, .3),
    0 0 0 10px rgba(215, 178, 76, .5);
  }
`;

export const PuzzleWrapper = styled.div`
  position: relative;
  height: 25vh;

  animation: 1s ${mountFinalAnimation} forwards;
  animation-delay: 2s;
`;

const mountPieceAnimation = keyframes`
  0% {
    transform: scale(1.2) translatex(20px) translatey(-20px);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: scale(1) translatex(0) translatey(0);
  }
`;

export const IconPiece = styled.img`
  width: 13vh;
  position: absolute;
  top: 0;
  right: 0;
  animation: 2s ${mountPieceAnimation};
  animation-timing-function: ease-in-out;
`;

const mountAnimation = keyframes`
  0% {
    background-color: transparent;
  }
  80% {
    opacity: 1;
  }
  100% {
    background-color: #D7B24C;
  }
`;

export const IconAnimated = styled.img`
  width: 25vh;
  height: auto;
  animation: 700ms ${mountAnimation} forwards;
  animation-delay: 1.8s;
`;

const Icon = styled.img`
  width: 25vh;
`;

export default Icon;

import React, { useContext, useEffect, memo } from 'react';
import { useHistory } from 'react-router-dom';

import { AppContext } from '../../../../contexts/AppContext';
import { ContribuicaoContext } from '../../../../contexts/ContribuicaoContext';

import MuiButton from '../../../../components/MuiButton';

import irParaNovaRota from '../../../../utils/irParaNovaRota';

import MuiSelect from './MuiSelect';
import { Title } from '../../../../styles/global';

function MelhorDiaParaContribuir() {
  const history = useHistory();
  const { appState, appDispatch } = useContext(AppContext);
  const { contribuicao, contribuicaoDispatch } = useContext(ContribuicaoContext);
  const { diaVencimento } = contribuicao;
  const { textoMeioDePagamento } = appState.cabecalho.breadcrumbs;
  const diasParaContribuir = [5, 10, 15, 20, 25];

  const rotaAnterior = '/formulario/2/contribuicao/meio-de-pagamento';

  const breadcrumb = {
    textoMeioDePagamento,
    textoValorDaContribuicao: '',
    textoDiaDoVencimento: '',
    textoMesDeInicio: '',
  };

  /**
   * Ao carregar o componente, altera a url anterior,
   * os breadcrumbs e a etapa atual.
   */
  useEffect(() => {
    appDispatch({ type: 'ALTERA_URL_ANTERIOR', payload: rotaAnterior });
    appDispatch({ type: 'ALTERA_BREADCRUMBS', payload: breadcrumb });
    appDispatch({ type: 'ALTERA_ETAPA_ATUAL', payload: 2 });
  }, []);

  /**
   * Monitora o estado diaVencimento. Quando alterado atualiza o cabeçalho
   * com o valor escolhido e reseta o mes da contribuicao.
   */
  useEffect(() => {
    contribuicaoDispatch({ type: 'ALTERA_MES_CONTRIBUICAO', payload: '' });
  }, [diaVencimento]);

  return (
    <>
      <Title secondary="true">
        Que <strong>dia deseja iniciar</strong> as contribuições?
      </Title>
      <MuiSelect
        items={diasParaContribuir}
        onChange={(e) => contribuicaoDispatch({ type: 'ALTERA_DIA_VENCIMENTO', payload: +e })}
        initialValue={diaVencimento || diasParaContribuir[0]}
      />
      <MuiButton
        primary="true"
        fullWidth
        onClick={() => {
          appDispatch({
            type: 'ALTERA_BREADCRUMBS',
            payload: {
              ...breadcrumb,
              textoDiaDoVencimento: `Dia ${diaVencimento}`,
            },
          });
          irParaNovaRota(history, 'formulario/2/contribuicao/mes-escolhido');
        }}
      >
        Continuar
      </MuiButton>
    </>
  );
}

export default memo(MelhorDiaParaContribuir);

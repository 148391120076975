import { useState } from 'react';

type Resposta = [
  boolean,
  () => void,
];

/**
 * Hook para exibição de multiplas modais
 * @returns {Resposta}
 * 1º parâmetro: booleano para exibição da modal
 * 2º parâmetro: função para alterar o valor da exibição da modal
 */
export default function useModal(): Resposta {
  const [isShowing, setIsShowing] = useState<boolean>(false);

  const toggle = () => {
    setIsShowing(!isShowing);
  };

  return [isShowing, toggle];
}

import React, {
  useCallback,
  useContext,
  useEffect,
  memo,
} from 'react';
import { useHistory } from 'react-router-dom';

import { AppContext } from '../../../../contexts/AppContext';
import { ContribuicaoContext } from '../../../../contexts/ContribuicaoContext';

import ButtonToContribute from './components/ButtonToContribute';
import irParaNovaRota from '../../../../utils/irParaNovaRota';

function FormaDeContribuicao() {
  const { contribuicaoDispatch } = useContext(ContribuicaoContext);
  const { appDispatch } = useContext(AppContext);

  const history = useHistory();

  /**
   * Atualiza o Cabecalho com o Título, o estado da url anterior
   * e etapa atual ao carregar o componente
   */
  useEffect(() => {
    appDispatch({ type: 'ALTERA_TITULO', payload: 'Existem duas formas de contribuir,' });
    appDispatch({ type: 'ALTERA_URL_ANTERIOR', payload: '/' });
    appDispatch({ type: 'ALTERA_ETAPA_ATUAL', payload: 1 });
  }, []);

  /**
   * Seleciona o tipo de contribuicao atualizando o estado,
   * atualiza o título do cabeçalho conforme a escolha do tipo de contribuição,
   * atualiza a próxima etapa e progresso,
   * Vai para a próxima rota
   * @param {number} tipoDeContribuicao - Tipo de contribuição.
   */
  const selecionaTipoDeContribuicao = useCallback((tipoDeContribuicao: number) => {
    appDispatch(
      {
        type: 'ALTERA_TITULO',
        payload: `${tipoDeContribuicao === 2 ? 'Contribuição Mensal' : 'Contribuicão Única'}`,
      },
    );

    appDispatch({ type: 'ALTERA_ETAPA_ATUAL', payload: 2 });
    contribuicaoDispatch({ type: 'ALTERA_FORMA_DE_CONTRIBUICAO', payload: tipoDeContribuicao });
    irParaNovaRota(history, 'formulario/2/contribuicao/meio-de-pagamento');
  }, []);

  return (
    <>
      <ButtonToContribute
        titulo="Mensal"
        texto="Contribua uma vez por mês no boleto ou crédito e se torne um cooperador."
        recomendado
        onClick={() => selecionaTipoDeContribuicao(2)}
      />
      <ButtonToContribute
        titulo="Única"
        texto="Você irá contribuir uma única vez e poderá repetir sua contribuição quando quiser."
        onClick={() => selecionaTipoDeContribuicao(1)}
      />
    </>
  );
}

export default memo(FormaDeContribuicao);

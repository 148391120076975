import React, {
  ReactChild,
  memo,
} from 'react';

import IconPuzzle from '../../statics/img/icon-puzzle.svg';
import IconAnimationPuzzle from '../../statics/img/puzzle.svg';
import IconAnimationPiecePuzzle from '../../statics/img/puzzle-piece.svg';
import Icon, {
  GreetingsWrapper, Header, IconPiece, PuzzleWrapper, IconAnimated,
} from './style';

interface Props {
  children?: ReactChild;
  icone?: boolean;
  animacao?: boolean;
  temBoleto?: string;
}

function GreetingsContainer({
  children,
  icone,
  animacao,
  temBoleto,
}: Props) {
  return (
    <>
      <Header>
        {icone && <Icon src={IconPuzzle} alt="Quebra Cabeça" />}
        {animacao && (
          <PuzzleWrapper>
            <IconAnimated src={IconAnimationPuzzle} alt="Quebra-cabeça" />
            <IconPiece src={IconAnimationPiecePuzzle} alt="Peça do quebra-cabeça" />
          </PuzzleWrapper>
        )}
      </Header>
      <GreetingsWrapper padding verticalCenter temBoleto={temBoleto}>
        {children}
      </GreetingsWrapper>
    </>
  );
}

export default memo(GreetingsContainer);

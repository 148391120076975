import styled from 'styled-components';

import { Container } from '../../styles/global';

const NoMatchContainer = styled(Container)`
  && {
    position: absolute;
    top: 0;
    height: 200px;

    background: #FFFFFF;

    border-top: 5px solid ${(props) => props.theme.cores.primaria};
  }
`;

export default NoMatchContainer;

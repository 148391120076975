import styled from 'styled-components';

interface LoadingProps {
  readonly full?: boolean;
}

const LoadingContainer = styled.div<LoadingProps>`
  position: absolute;
  max-width: 600px;
  right: 20px;
  top: 20px;
  margin: 0 auto;

  ${(props) => props.full && `
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    width: 100vw;
    height: 80vh;
    top: 0px;
    right: initial;
    padding: 0 40px;

    background: #FFF;

    z-index: 10;
  `}

  div {
    color: ${(props) => (props.theme.cores.primaria)};
  }
`;

export default LoadingContainer;

import React, { memo } from 'react';
import { CircularProgress } from '@material-ui/core';

import { Text } from '../../styles/global';

import LoadingContainer from './styles';

interface LoadingProps {
  text?: string,
  size?: number
  full?: boolean,
}

function Loading({ text, size = 25, full }: LoadingProps) {
  return (
    <LoadingContainer full={full}>
      <CircularProgress size={size} />
      {text && (
        <Text weight="400" topspacing="2rem">
          {text}
        </Text>
      )}
    </LoadingContainer>
  );
}

export default memo(Loading);

import { RouteComponentProps } from 'react-router-dom';

/**
 * Push para uma nova rota
 * @param history - Componente obtido pelo useHistory()
 * @param rota - Rota a ser navegada
 */
export default function irParaNovaRota(history: RouteComponentProps['history'], rota: string) : void {
  history.push(`/${rota}`);
}

import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  margin: var(--espacamento-lg) 0;

  button:last-child {
    grid-column: span 2;

    text-transform: uppercase;
  }

  button div:last-child {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    width: 100%;

    padding: 0;
  }

  p {
    width: 100%;

    span {
      font-size: 1.75rem;
    }
  }
`;

export default Container;

import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  memo,
} from 'react';

import { AppContext } from '../../../../contexts/AppContext';
import { DadosCadastraisContext } from '../../../../contexts/DadosCadastraisContext';

import MuiInput from '../../../../components/MuiInput';
import MuiButton from '../../../../components/MuiButton';
// import MuiCheckbox from '../../../../components/MuiCheckbox';

import useRouter from '../../../../utils/hooks/useRouter';
import useDebounce from '../../../../utils/hooks/useDebounce';

// função para validação de DNS
// import validaDominioPorDnsGoogle from '../../../../utils/validaDominioPorDnsGoogle';

// padrão RFC5322
const regex = /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([\]!#-[^-~ \t]|(\\[\t -~]))+")@([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\[[\t -Z^-~]*])/;

function Email() {
  const router = useRouter();
  const { appState, appDispatch } = useContext(AppContext);
  const { dadosCadastrais, dadosCadastraisDispatch } = useContext(DadosCadastraisContext);
  const { email } = dadosCadastrais.contatos;
  const [valido, setValido] = useState<boolean>(false);
  // const [naoPossuiEmail, setNaoPossuiEmail] = useState<boolean | undefined>(email?.naoPossui);
  // const [valorDigitado, setValorDigitado] = useState<string>('');

  /**
   * Valida se o email digitado é válido ou não
   * @param {string} endereco - endereco de email digitado
   * @return {Promise<boolean>} - se é válido ou não
   */
  const validaEmailDigitado = useCallback(async (endereco: string) => {
    const strValida = regex.test(endereco);

    /**
     * Algoritmo de Validação por DNS.
     * Possivelmente utiliaar uma API como proxy para validar ao invés
     * de diretamente chamar na aplicação resolva o problema e servia de melhoria
     * para validação de emails, impossibilitando emails sem registros MX
     */
    // const dominio = endereco.substring(endereco.indexOf('@') + 1);
    // const dominioValido = await validaDominioPorDnsGoogle(dominio);
    // setValido(dominioValido);
    // return dominioValido;

    setValido(strValida);
    return strValida;
  }, []);

  useEffect(() => {
    appDispatch({ type: 'MANIPULA_DISABLED', payload: !valido });
  }, [valido]);

  /**
   * Manipula os dados digitado pelo usuario
   * Se o email for valido, atualiza o estado de email
   * @param {string} valor - Valor digitado pelo usuario
   */
  const manipulaInputEmail = useCallback(async (valor: string) => {
    const emailFormatado = {
      tipo: 1,
      endereco: valor,
      naoPossui: false,
    };

    const emailValido = await validaEmailDigitado(valor);

    if (emailValido) {
      dadosCadastraisDispatch({ type: 'ALTERA_EMAIL', payload: emailFormatado });
    }
  }, []);

  const debouncedInputEmail = useDebounce(manipulaInputEmail);

  /**
   * Manipula a checkbox para marcar se possui ou não email
   * Atualiza estado "naoPossuiEmail" conforme o checar ou deschecar o checkbox
   * Se checado atualiza o email com endereço null
   * @param {ChangeEvent<HTMLInputElement>} event - evento disparado ao usuario clicar na checkbox
   */
  // const manipulaNaoPossuoEmail = useCallback((event: ChangeEvent<HTMLInputElement>) => {
  //   const checado = event.target.checked;

  //   setNaoPossuiEmail(checado);

  //   if (checado) {
  //     const naoPossuiEmailFormatado = {
  //       tipo: null,
  //       endereco: null,
  //       naoPossui: true,
  //     };

  //     dadosCadastraisDispatch({ type: 'ALTERA_EMAIL', payload: naoPossuiEmailFormatado });
  //   } else {
  //     dadosCadastraisDispatch({ type: 'ALTERA_EMAIL', payload: { ...email, naoPossui: false } });
  //   }
  // }, []);

  /** Monitora estado "naoPossuiEmail"
   * Ao estado alterar verifica:
   * - se possui email gravado no localStorage, se tiver, permite continuar
   * - se possuir email e o não tiver valor digitado ou o valor for invalido, não permite continuar
   * - se não possui email ou possuiEmail e o valor digitado for válido, permite continuar
   * - Se tiver o valor digitado e possuir email grava valor no estado de email
  */
  // useEffect(() => {
  //   if (email?.endereco && valido) {
  //     appDispatch({ type: 'MANIPULA_DISABLED', payload: false });
  //   } else if (!naoPossuiEmail && (!valorDigitado || !valido)) {
  //     appDispatch({ type: 'MANIPULA_DISABLED', payload: true });
  //   } else if (naoPossuiEmail || (!naoPossuiEmail && valido)) {
  //     appDispatch({ type: 'MANIPULA_DISABLED', payload: false });
  //   }

  //   if (valorDigitado && !naoPossuiEmail) {
  //     dadosCadastraisDispatch({
  //        type: 'ALTERA_EMAIL', payload: { ...email, endereco: valorDigitado }
  //     });
  //   }
  // }, [naoPossuiEmail]);

  /**
   * Ao carregar o componente, atualiza a etapa atual e a url anterior
   * Verifica se tem estado inicial, caso tenha, valida o estado
   */
  useEffect(() => {
    appDispatch({ type: 'ALTERA_ETAPA_ATUAL', payload: 4 });
    appDispatch({ type: 'ALTERA_URL_ANTERIOR', payload: '/formulario/4/dados-pessoais/celular' });

    if (email?.endereco === '') {
      appDispatch({ type: 'MANIPULA_DISABLED', payload: true });
    } else if (email && email.endereco) {
      validaEmailDigitado(email.endereco);
    }
  }, []);

  return (
    <>
      <MuiInput
        name="email"
        label="E-mail"
        type="email"
        gutterbottom="true"
        validacao={valido}
        valorInicial={email?.endereco || ''}
        onChange={debouncedInputEmail}
      />
      {/* <MuiCheckbox
        label="Não possuo e-mail"
        checado={email?.naoPossui}
        onChange={manipulaNaoPossuoEmail}
      /> */}
      <MuiButton
        primary="true"
        fullWidth
        disabled={appState.disabled}
        onClick={() => router.push('/formulario/4/dados-pessoais/endereco')}
      >
        Continuar
      </MuiButton>
    </>
  );
}

export default memo(Email);

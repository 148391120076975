import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Alert } from '@material-ui/lab';

import { post, get } from '../../../services/api';
import getUrlApi from '../../../services/url';

import { AppContext } from '../../../contexts/AppContext';
import { ContribuicaoContext, ContribuicaoState } from '../../../contexts/ContribuicaoContext';
import { DadosCadastraisContext, DadosCadastraisState, ITermo } from '../../../contexts/DadosCadastraisContext';
import { BoletoContext } from '../../../contexts/BoletoContext';

import MuiButton from '../../../components/MuiButton';
import Loading from '../../../components/Loading';

import useRouter from '../../../utils/hooks/useRouter';
import detectaDispositivo from '../../../utils/detectaDispositivo';
import formataCelularParaPagarme from '../../../utils/formataCelularParaPagarme';
import initWebcheckout from '../../../utils/initWebcheckout';
import getTermo from '../../../utils/getTermo';
import getMesAtual from '../../../utils/getMesAtual';

import { Title, Text } from '../../../styles/global';
import libcom from '../../../styles/themes/libcom';

interface IBodyContribuicao {
  idPessoa: number | null,
  cooperador: boolean | null,
  contribuicao: ContribuicaoState,
  pessoa: Omit<DadosCadastraisState, 'idPessoa' | 'cooperador'>,
}
interface IConfigCheckoutPagarme {
  postbackUrl?: string
  uiColor: string,
  paymentButtonText: string,
  headerText: string,
  amount: number,
  createToken: boolean,
  paymentMethods: 'credit_card' | 'boleto',
  customerData: boolean,
  customer: {
    // eslint-disable-next-line camelcase
    external_id: string,
    name: string,
    type: 'individual',
    country: 'br',
    email?: string | null,
    documents: [
      {
        type: 'cpf',
        number: string
      },
    ],
    // eslint-disable-next-line camelcase
    phone_numbers: string[],
    birthday: string
  },
  boletoExpirationDate?: string,
  billing: {
    name: string,
    address: {
      country: 'br',
      state: string,
      city: string,
      neighborhood: string
      street: string,
      // eslint-disable-next-line camelcase
      street_number: string | null,
      complementary?: string,
      zipcode: string,
    }
  },
  items: [
    {
      id: string,
      title: string,
      // eslint-disable-next-line camelcase
      unit_price: number,
      quantity: 1,
      tangible: false,
    },
  ],
}

interface TermoProps extends ITermo {
  texto?: string,
}

let checkoutPagarme: any;

function Termo() {
  const router = useRouter();

  const [erro, setErro] = useState<string>('');
  const [termo, setTermo] = useState<TermoProps>();

  const { appState, appDispatch } = useContext(AppContext);
  const { contribuicao } = useContext(ContribuicaoContext);
  const { dadosCadastrais, dadosCadastraisDispatch } = useContext(DadosCadastraisContext);
  const { boletoDispatch } = useContext(BoletoContext);

  const {
    tipo,
    meioDePagamento,
    dataInicio,
    valorContribuicao,
  } = contribuicao;

  const {
    idPessoa,
    cooperador,
    endereco,
    contatos,
    termos,
  } = dadosCadastrais;
  const { celular } = dadosCadastrais.contatos;
  const { nome, cpf, dataNascimento } = dadosCadastrais.dados;

  /** Requisicao para retornar o termo e guardar no estado termo */
  const recebeTermo = useCallback(async () => {
    setTermo(await getTermo(tipo === 1 ? 6 : 5));
  }, []);

  /** Monitora estado termo, ao ser alterado, atualiza o state termos */
  useEffect(() => {
    if (termo) {
      const termoAceito = {
        idTermo: termo.idTermo,
        idTermoTipo: termo.idTermoTipo,
        dispositivo: detectaDispositivo(),
        aceito: true,
      };

      const verificaTermosDuplicados = termos.filter(
        (term: any) => term.idTermo !== termoAceito.idTermo,
      );

      dadosCadastraisDispatch({ type: 'ALTERA_TERMOS', termos: [...verificaTermosDuplicados, termoAceito] });
    }
  }, [termo]);

  /**
   * Ao carregar componente atualiza etapa atual e url anterior
   * Inicia a instância do Checkout da pagarme
   */
  useEffect(() => {
    appDispatch({ type: 'ALTERA_ETAPA_ATUAL', payload: 5 });
    appDispatch({ type: 'MANIPULA_DISABLED', payload: false });
    appDispatch({ type: 'MANIPULA_CARREGANDO', payload: false });
    appDispatch({ type: 'ALTERA_URL_ANTERIOR', payload: '/formulario/4/dados-pessoais/endereco' });
    recebeTermo();
    initWebcheckout(() => {
      checkoutPagarme = window.PagarMeCheckout;
    });
  }, []);

  const formataMeioDePagamento = useMemo(() => (meioDePagamento === 3 ? 'boleto' : 'credit_card'), []);
  const formataValorDaContribuicao = useMemo(() => {
    if (tipo === 2 && meioDePagamento === 5) {
      return 100;
    }
    return (valorContribuicao * 100);
  }, []);

  /**
   * Ao usuario aceitar o termo
   */
  const aceitaTermo = useCallback(async () => {
    appDispatch({ type: 'MANIPULA_CARREGANDO', payload: true });
    const body: IBodyContribuicao = {
      idPessoa,
      cooperador,
      contribuicao,
      pessoa: {
        app: 'App Contribua',
        dados: {
          cpf: cpf.replace(/[^0-9]+/g, ''),
          nome,
          dataNascimento,
        },
        contatos: {
          celular,
          email: {
            tipo: contatos.email?.tipo,
            endereco: contatos.email?.endereco,
          },
        },
        endereco,
        termos,
      },
    };

    try {
      const retorno = await post('api-contribua', '/contribuicoes', body);
      if (retorno?.status === 200) {
        const { idContribuicao } = retorno.data.objInserido;
        const { pedidoOdoo } = retorno.data;

        const configCheckoutPagarme: IConfigCheckoutPagarme = {
          uiColor: libcom.cores.primaria,
          paymentButtonText: 'Contribuir',
          headerText: ' ',
          amount: formataValorDaContribuicao,
          createToken: true,
          paymentMethods: formataMeioDePagamento,
          customerData: false,
          customer: {
            external_id: `${idPessoa}`,
            name: nome,
            type: 'individual',
            country: 'br',
            email: contatos.email?.endereco,
            documents: [
              {
                type: 'cpf',
                number: cpf,
              },
            ],
            phone_numbers: [formataCelularParaPagarme(contatos.celular.numero)],
            birthday: dataNascimento,
          },
          billing: {
            name: nome,
            address: {
              country: 'br',
              state: endereco.estado,
              city: endereco.cidade,
              neighborhood: endereco.bairro,
              street: endereco.logradouro,
              street_number: endereco && (endereco.numero && endereco.numero.toString()),
              zipcode: endereco.cep.replace('-', ''),
            },
          },
          items: [
            {
              id: `${pedidoOdoo.id}`,
              title: `${pedidoOdoo.nome}`,
              // title: `${pedidoOdoo.numero} | ${pedidoOdoo.codigo} | ${pedidoOdoo.nome}`,
              // eslint-disable-next-line camelcase
              unit_price: formataValorDaContribuicao,
              quantity: 1,
              tangible: false,
            },
          ],
        };

        if (endereco) {
          if (endereco.complemento !== '' && endereco.complemento !== null) {
            configCheckoutPagarme.billing.address.complementary = endereco.complemento;
          }
        }

        if (dataInicio !== null) {
          configCheckoutPagarme.boletoExpirationDate = dataInicio;
        }

        if (contribuicao.meioDePagamento === 5) {
          configCheckoutPagarme.postbackUrl = `${getUrlApi('api-contribua')}/contribuicoes/${pedidoOdoo.id}/pagarme`;
        }

        const checkout = new checkoutPagarme.Checkout({
          encryption_key: process.env.REACT_APP_PAGARME_ENCRYPTION_KEY,
          success: async (data: any) => {
            if (contribuicao) {
              const tipoContribuicao = contribuicao.tipo;
              let dataInicioFormatada;
              const hojeMaisDez = new Date();
              hojeMaisDez.setDate(new Date().getDate() + 10);
              if (contribuicao.dataInicio) {
                dataInicioFormatada = new Date(
                  parseInt(contribuicao.dataInicio.split('-')[0], 10),
                  (parseInt(contribuicao.dataInicio.split('-')[1], 10) - 1),
                  parseInt(contribuicao.dataInicio.split('-')[2], 10),
                );
              }
              const inicioContribuicaoMenorHojeMaisDez = dataInicioFormatada && (
                dataInicioFormatada <= hojeMaisDez
              );
              if (data.payment_method === 'boleto' && (tipoContribuicao === 1 || (tipoContribuicao === 2 && inicioContribuicaoMenorHojeMaisDez))) {
                try {
                  const boleto = await get('api-contribua', `/captura-transacao-pagarme/${data.token}?valor=${formataValorDaContribuicao}`, {});
                  if (boleto) {
                    const boletoProps = {
                      boletoURL: boleto.data.boletoURL,
                      boletoBarcode: boleto.data.boletoBarcode,
                      boletoExpirationDate: boleto.data.boletoExpirationDate,
                    };
                    boletoDispatch({ type: 'ALTERA_BOLETO', payload: boletoProps });
                    router.push('/contribuicao-concluida');
                  }
                } catch (error) {
                  console.log(error);
                }
              } else {
                boletoDispatch({ type: 'RESET_BOLETO' });
                router.push('/contribuicao-concluida');
              }
              appDispatch({ type: 'MANIPULA_CARREGANDO', payload: false });
            }
          },
          error: (error: any) => {
            setErro('Oooops... ocorreu um erro no servidor, tente novamente mais tarde!');
            setTimeout(() => {
              setErro('');
            }, 4000);
          },
          close: () => {
            appDispatch({ type: 'MANIPULA_DISABLED', payload: true });
            setTimeout(() => {
              checkout.open(configCheckoutPagarme);
            }, 700);
          },
        });

        checkout.open(configCheckoutPagarme);
      }

      setErro('');
    } catch (error) {
      if (error.data) {
        setErro(error.data.mensagem);
      } else {
        setErro('Oops... Ocorreu um erro inesperado, tente novamente!');
      }
      setTimeout(() => {
        setErro('');
      }, 4000);
      appDispatch({ type: 'MANIPULA_CARREGANDO', payload: false });
    }
  }, [termos]);

  const getDataEspecifica = useCallback((data: string) => {
    if (dataInicio) {
      const dataFormatada = dataInicio?.split('-');
      switch (data) {
        case 'ANO':
          return dataFormatada[0];
        case 'MES':
          return getMesAtual((parseInt(dataFormatada[1], 10) - 1));
        case 'DIA':
          return dataFormatada[2];
        default:
          return 'Informe um parâmetro válido';
      }
    }
    return false;
  }, []);

  return (
    <>
      {appState.carregando && <Loading text="Aguarde um momento... estamos concluindo sua contribuição." size={60} full />}
      <Title secondary="true" gutterBottom align="left" style={{ width: '100%' }}>
        Autorização de contribuição
      </Title>
      {termo ? (
        <Text align="left" gutterBottom size="2.2rem" weight="400">
          {termo.texto}
        </Text>
      ) : (
        <Loading size={60} full />
      )}
      {tipo === 2 && meioDePagamento === 5 && (
        <Text gutterBottom weight="400" align="left" size="2.2rem">
          Ao concluir faremos a reserva de um real para validação do seu cartão de crédito,
          mas o valor não será cobrado em sua fatura. A primeira parcela será cobrada
          apenas no dia {getDataEspecifica('DIA')} de {getDataEspecifica('MES')} de {getDataEspecifica('ANO')}.
        </Text>
      )}
      <Text gutterBottom weight="700" align="left" size="2.2rem">
        Ao continuar você estará de acordo.
      </Text>
      <MuiButton
        primary="true"
        fullWidth
        disabled={appState.disabled}
        onClick={aceitaTermo}
      >
        Aceitar e Continuar
      </MuiButton>
      {erro && (
        <Alert severity="error" style={{ fontSize: '2rem' }}>
          {erro}
        </Alert>
      )}
    </>
  );
}

export default Termo;

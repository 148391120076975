import axios, { AxiosResponse, Method } from 'axios';

import getUrlApi from './url';

const api = axios.create();

const initialHeaders: object = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'DELETE, POST, PUT, GET, OPTIONS',
  'Access-Control-Allow-Headers': '*',
  'Access-Control-Max-Age': 86400,
  schema: 'libcom',
};

async function request(
  method: Method, url: string, headers: object, body?: object,
): Promise<AxiosResponse | undefined> {
  let response;

  try {
    response = await api({
      method,
      url,
      headers,
      data: JSON.stringify(body),
    });
  } catch (error) {
    if (error.response.status >= 400 && error.response.status <= 500) {
      throw error.response;
    }
  }

  return response;
}

function getFinalUrl(apiName: string, url: string): string {
  return getUrlApi(apiName) + url;
}
function getFinalHeaders(headers?: object): object {
  return headers ? { ...initialHeaders, ...headers } : initialHeaders;
}

// Métodos HTTP
// TODO: Será que não fica melhor apenas uma função passando o método como parâmetro?
let method: Method;
let finalUrl: string;

export function get(apiName: string, url: string, headers: object) {
  method = 'get';
  finalUrl = getFinalUrl(apiName, url);

  return request(method, finalUrl, getFinalHeaders(headers));
}
export function post(apiName: string, url: string, body: object, headers?: object) {
  method = 'post';
  finalUrl = getFinalUrl(apiName, url);

  return request(method, finalUrl, getFinalHeaders(headers), body);
}
export function put(apiName: string, url: string, body: object, headers?: object) {
  method = 'put';
  finalUrl = getFinalUrl(apiName, url);

  return request(method, finalUrl, getFinalHeaders(headers), body);
}

import { useEffect } from 'react';

/**
 * Abstração do tratamento do evento "beforeunload" para um hook customizável com useEffect.
 * @summary Recebe um callback. Chama o useEffect uma vez ao montar
 * o componente e define o manipulador de eventos com o callback e retorna uma função de 'limpeza'
 * para remover o manipulador de eventos, quando o componente for removido.
 * @param {Function} callback - Função
 */
function useUnload(callback: any) {
  useEffect(() => {
    window.addEventListener('beforeunload', callback);

    return () => window.removeEventListener('beforeunload', callback);
  }, []);
}

export default useUnload;

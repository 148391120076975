import React, { useContext, useEffect, memo } from 'react';
import { useHistory } from 'react-router-dom';

import { AppContext, IStateBreadcrumbs } from '../../../../contexts/AppContext';
import { ContribuicaoContext } from '../../../../contexts/ContribuicaoContext';

import RadioGroup from '../../../../components/RadioGroup';
import MuiButton from '../../../../components/MuiButton';

import irParaNovaRota from '../../../../utils/irParaNovaRota';
import { Title } from '../../../../styles/global';

const MEIOS_DE_PAGAMENTO = [
  {
    tipo: '5',
    label: 'Cartão de crédito',
  },
  {
    tipo: '3',
    label: 'Boleto',
  },
];

function MeioDePagamento() {
  const history = useHistory();
  const { appState, appDispatch } = useContext(AppContext);
  const { contribuicao, contribuicaoDispatch } = useContext(ContribuicaoContext);
  const { meioDePagamento } = contribuicao;

  const proximaRota = contribuicao?.tipo === 2 ? 'formulario/2/contribuicao/melhor-dia' : 'formulario/3/contribuicao/informa-valor';
  const rotaAnterior = '/formulario/1/contribuicao/forma-de-contribuicao';

  const breadcrumb: IStateBreadcrumbs = {
    textoMeioDePagamento: '',
    textoValorDaContribuicao: '',
    textoDiaDoVencimento: '',
    textoMesDeInicio: '',
  };

  /**
   * Atualiza os estados da url anterior, breadcrumb e etapa atual
   * ao carregar o componente
   */
  useEffect(() => {
    appDispatch({ type: 'ALTERA_URL_ANTERIOR', payload: rotaAnterior });
    appDispatch({ type: 'ALTERA_BREADCRUMBS', payload: breadcrumb });
    appDispatch({ type: 'ALTERA_ETAPA_ATUAL', payload: 2 });
  }, []);

  /**
   * Monitora o estado meioDePagamento. Quando alterado atualiza o cabeçalho
   * com o valor escolhido e desabilitad o botao caso o usuario não selecione
   * nenhuma opção.
   */
  useEffect(() => {
    if (meioDePagamento === 0) {
      appDispatch({ type: 'MANIPULA_DISABLED', payload: true });
    } else {
      appDispatch({ type: 'MANIPULA_DISABLED', payload: false });
    }
  }, [meioDePagamento]);

  return (
    <>
      <Title secondary="true">
        Escolha o meio de pagamento:
      </Title>
      <RadioGroup
        items={MEIOS_DE_PAGAMENTO}
        onChange={(e) => contribuicaoDispatch({ type: 'ALTERA_MEIO_DE_PAGAMENTO', payload: parseInt(e, 10) })}
        initialValue={meioDePagamento.toString()}
      />
      <MuiButton
        primary="true"
        fullWidth
        disabled={appState.disabled}
        onClick={() => {
          appDispatch({
            type: 'ALTERA_BREADCRUMBS',
            payload: {
              ...breadcrumb,
              textoMeioDePagamento: meioDePagamento === 3 ? 'Boleto' : 'Cartão de Crédito',
            },
          });
          irParaNovaRota(history, proximaRota);
        }}
      >
        Continuar
      </MuiButton>
    </>
  );
}

export default memo(MeioDePagamento);

import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useMemo,
  memo,
} from 'react';

import { AppContext } from '../../../../contexts/AppContext';
import { DadosCadastraisContext } from '../../../../contexts/DadosCadastraisContext';

import MuiInput from '../../../../components/MuiInput';
import MuiButton from '../../../../components/MuiButton';

import useRouter from '../../../../utils/hooks/useRouter';

function DataNascimento() {
  const router = useRouter();
  const { appState, appDispatch } = useContext(AppContext);
  const { dadosCadastrais, dadosCadastraisDispatch } = useContext(DadosCadastraisContext);
  const [valido, setValido] = useState<boolean>(true);

  const { dataNascimento } = dadosCadastrais.dados;

  let anoInformado: string | number;
  let mesInformado: string | number;
  let diaInformado: string | number;

  useEffect(() => {
    appDispatch({ type: 'MANIPULA_DISABLED', payload: !valido });
  }, [valido]);

  /**
   * Valida se a data digitada está correta
   * @param {string} data - Data digitado pelo usuario
   * @return {boolean} Se é valido ou não a data digitada
   */
  const validaDataDigitada = useCallback((data: string): boolean => {
    const anoAtual = new Date().getFullYear();
    anoInformado = Number(data.split('/')[2]);
    mesInformado = Number(data.split('/')[1]);
    diaInformado = Number(data.split('/')[0]);

    const resultado = (
      +anoInformado < anoAtual
      && +anoInformado > (anoAtual - 130)
    );

    setValido(resultado);

    return resultado;
  }, []);

  /**
   * Ao digitar valida a data digitada pelo usuario,
   * se for valida salva o valor no estado dataNascimento
   * @param {string} valor - Valor digitado pelo usuario
   */
  const manipulaInputData = useCallback((valor: string) => {
    const regex = /(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))/;
    let dataValida;
    if (valor.length === 11) {
      return;
    }

    if (valor) {
      dataValida = regex.test(valor) && validaDataDigitada(valor);
    } else {
      dadosCadastraisDispatch({ type: 'ALTERA_DATA_NASCIMENTO', payload: '' });
      appDispatch({ type: 'MANIPULA_DISABLED', payload: true });
      return;
    }

    appDispatch({ type: 'MANIPULA_DISABLED', payload: !dataValida });

    if (dataValida) {
      const mesFormatadoComZero = mesInformado > 9 ? `${mesInformado}` : `0${mesInformado}`;
      const diaFormatadoComZero = diaInformado > 9 ? `${diaInformado}` : `0${diaInformado}`;

      const dataNascimentoFormatada = `${anoInformado}-${mesFormatadoComZero}-${diaFormatadoComZero}`;

      dadosCadastraisDispatch({ type: 'ALTERA_DATA_NASCIMENTO', payload: dataNascimentoFormatada });
    }
  }, []);

  const formataValorInicial = useMemo((): string => {
    if (dataNascimento) {
      const ano = dataNascimento.split('-')[0];
      const mes = dataNascimento.split('-')[1];
      const dia = dataNascimento.split('-')[2];

      return `${dia}/${mes}/${ano}`;
    }

    return '';
  }, []);

  /**
   * Ao carregar o componente atualiza a url anterior
   * Formata data de ISO para padrão brasileiro
   */
  useEffect(() => {
    appDispatch({ type: 'ALTERA_URL_ANTERIOR', payload: '/formulario/4/dados-pessoais/nome' });

    if (dadosCadastrais.dados.dataNascimento === '') {
      appDispatch({ type: 'MANIPULA_DISABLED', payload: true });
    } else {
      validaDataDigitada(formataValorInicial);
    }
  }, []);

  return (
    <>
      <MuiInput
        name="data-nascimento"
        label="Data de nascimento"
        gutterbottom="true"
        validacao={valido}
        valorInicial={formataValorInicial}
        onChange={manipulaInputData}
        type="tel"
        inputMask="99/99/9999"
      />
      <MuiButton
        primary="true"
        fullWidth
        disabled={appState.disabled}
        onClick={() => router.push('/formulario/4/dados-pessoais/celular')}
      >
        Continuar
      </MuiButton>
    </>
  );
}

export default memo(DataNascimento);

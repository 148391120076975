export type MascarasType = 'MOEDA_BR'
  | 'CPF'
  | 'PRIMEIRA_LETRA_MAIUSCULA'
  | 'DATA'
  | 'CELULAR'
  | 'CEP';

/**
 * Mascaras para os campos de input
 * @param {MascarasType} mascara - Mascara
 * @param {string} valorFormatado - valor a ser formatado
 * @returns {string} - valor formatado
 */
export default function mascaras(mascara: MascarasType, valorFormatado: string): string {
  let temp = valorFormatado;

  switch (mascara) {
    case 'MOEDA_BR':
      if (temp === 'NaN') {
        // eslint-disable-next-line no-param-reassign
        valorFormatado = '';
        return valorFormatado;
      }

      temp = temp.replace(/[\D]+/g, '');
      temp = temp.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

      // eslint-disable-next-line no-param-reassign
      valorFormatado = temp;
      return valorFormatado;

    case 'CPF':
      return valorFormatado
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

    case 'PRIMEIRA_LETRA_MAIUSCULA':
      // eslint-disable-next-line no-case-declarations
      const regex = /^da$|^de$|^do$|^das$|^dos$/;
      // eslint-disable-next-line no-case-declarations
      const palavras = valorFormatado.toLowerCase().split(' ');

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < palavras.length; i++) {
        if (!regex.test(palavras[i])) {
          const primeiraLetra = palavras[i].charAt(0).toUpperCase();
          const restanteDaPalavra = palavras[i].substr(1);
          palavras[i] = `${primeiraLetra}${restanteDaPalavra}`;
        }
      }
      // eslint-disable-next-line no-param-reassign
      valorFormatado = palavras.join(' ');

      return valorFormatado;

    case 'DATA':
      return valorFormatado
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})\d+?$/, '$1');

    case 'CELULAR':
      return valorFormatado
        .replace(/\D/g, '')
        .replace(/^(\d\d)(\d)/, '($1) $2')
        .replace(/(\s\d{1})(\d)/, '$1 $2')
        .replace(/(\d{4})(\d)/, '$1 $2')
        .replace(/(\s\d{4})\d+?$/, '$1');

    case 'CEP':
      return valorFormatado
        .replace(/\D/g, '')
        .replace(/^(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1');

    default:
      return valorFormatado;
  }
}

import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import { Title, Text } from '../../styles/global';

import { AppContext } from '../../contexts/AppContext';
import { ContribuicaoContext } from '../../contexts/ContribuicaoContext';
import { DadosCadastraisContext, ITermo } from '../../contexts/DadosCadastraisContext';

import MuiButton from '../../components/MuiButton';
import GreetingsContainer from '../../components/GreetingsContainer';
import Modal from '../../components/Modal';
import ModalHelpContent from '../../components/ModalHelpContent';
import irParaNovaRota from '../../utils/irParaNovaRota';
import useModal from '../../utils/hooks/useModal';

import LGPD from './components/LGPD';
import getTermo from '../../utils/getTermo';
import detectaDispositivo from '../../utils/detectaDispositivo';

export interface ITermoProps extends ITermo {
  texto?: string,
  titulo?: string,
}

const dicionarioTermos: Omit<ITermoProps, 'dispositivo' | 'aceito'>[] = [
  {
    idTermoTipo: 1,
    idTermo: 0,
    titulo: 'Política de Privacidade',
    texto: 'Política de Privacidade',
  },
  {
    idTermoTipo: 4,
    idTermo: 0,
    titulo: 'Termo de uso',
    texto: 'Termo de uso',
  },
];

function Home() {
  const history = useHistory();

  const [abreModal, toggle] = useModal();

  const [termos, setTermos] = useState(dicionarioTermos);

  const { appDispatch } = useContext(AppContext);
  const { contribuicaoDispatch } = useContext(ContribuicaoContext);
  const { dadosCadastraisDispatch } = useContext(DadosCadastraisContext);

  const recebeTermo = useCallback(async () => {
    const termosObtidos: any = [];
    termos?.map(async (termo) => {
      try {
        const retorno = await getTermo(termo.idTermoTipo);
        const { idTermo, texto } = retorno;

        termosObtidos.push({
          ...termo,
          idTermo,
          texto,
        });
        setTermos([...termos, { ...termo, idTermo, texto }]);
      } catch (error) {
        console.log(error);
      }

      setTermos(termosObtidos);
    });
  }, [termos]);

  useEffect(() => {
    appDispatch({ type: 'INICIA_CONTRIBUICAO', payload: false });
    recebeTermo();
  }, []);

  /**
   * Inicia uma nova contribuição,
   * altera a Etapa atuala para 1 e
   * avança para a página de 'Formas de contribuir'.
   */
  const iniciaContribuicao = useCallback(async () => {
    appDispatch({ type: 'RESET_DADOS' });
    contribuicaoDispatch({ type: 'RESET_DADOS' });
    dadosCadastraisDispatch({ type: 'RESET_DADOS' });
    appDispatch({ type: 'INICIA_CONTRIBUICAO', payload: true });
    appDispatch({ type: 'ALTERA_ETAPA_ATUAL', payload: 1 });

    if (termos.length) {
      const termosAceitos = termos.map((termo) => ({
        idTermo: termo.idTermo,
        idTermoTipo: termo.idTermoTipo,
        aceito: true,
        dispositivo: detectaDispositivo(),
      }));
      dadosCadastraisDispatch({ type: 'ALTERA_TERMOS', termos: termosAceitos });
    }

    irParaNovaRota(history, 'formulario/1/contribuicao/forma-de-contribuicao');
  }, [termos]);

  return (
    <div>
      <LGPD termos={termos} />
      <GreetingsContainer icone>
        <>
          <Title align="center" gutterBottom>
            Sua contribuição
            <br />
            é muito importante!
          </Title>
          <Text align="center" gutterBottom>
            <>
              Através da sua colaboração e
              parceria, podemos levar nossos ideais para mais pessoas.
              Muito obrigado por realizar tudo isso conosco!
            </>
          </Text>
          <MuiButton
            primary="true"
            fullWidth
            onClick={() => iniciaContribuicao()}
          >
            Veja como funciona
          </MuiButton>
          <MuiButton onClick={() => toggle()}>
            <>
              Alguma dúvida ou já é cooperador?
              <br />
              Fale conosco.
            </>
          </MuiButton>
        </>
      </GreetingsContainer>
      <Modal
        exibe={abreModal}
        toggle={toggle}
        cabecalho={(
          <>
            <Title size="2.5rem" fontcolor="#FFFFFF">Fale Conosco</Title>
          </>
        )}
        conteudo={(<ModalHelpContent />)}
      />
    </div>
  );
}

export default Home;

import { Typography, InputAdornment } from '@material-ui/core';
import styled, { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }


  :root {
    --espacamento-xsm: 1rem;
    --espacamento-sm: 2rem;
    --espacamento-m: 3rem;
    --espacamento-lg: 4rem;
    --espacamento-xlg: 5rem;

    --border-radius: 0.75rem;

    --box-shadow: 0 2px 2px rgba(0,0,0,.3);
  }

  html {
    font-size: 1.8vmin;

    @media (min-width: 600px) {
      font-size: 8px;
    }
  }

  body {
    background: ${(props) => props.theme.cores.background};
    font-family: 'Open Sans', sans-serif;
    color: ${(props) => props.theme.cores.texto};

    .MuiAutocomplete-popper > div {
      font-size: 2.3rem !important;
    }
  }
`;

interface ContainerProps {
  readonly hidden?: boolean;
  readonly column?: boolean;
  readonly base?: boolean;
  readonly padding?: boolean;
  readonly verticalCenter?: boolean;
  readonly horizontalCenter?: boolean;
}

export const Container = styled.section<ContainerProps>`
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  flex: 1;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  flex-wrap: wrap;

  position: relative;
  margin: 0 auto;
  padding: ${(props) => (props.padding ? 'var(--espacamento-xlg)' : 'var(--espacamento-m)')};
  max-width: 600px;

  font-size: 1rem;
  text-align: center;

  /* Estica conteúdo */
  ${(props) => (props.base && '> * { flex: 1 }')}

  /* Centraliza na Vertical */
  ${(props) => (props.verticalCenter && 'justify-content: center;')}

  /* Centraliza na Horizontal */
  ${(props) => (props.horizontalCenter && 'align-items: center;')}
`;

interface TitleProps {
  readonly size?: string;
  readonly fontcolor?: string;
  readonly bottomspacing?: string;
  readonly weight?: string;
  readonly secondary?: 'true';
}
export const Title = styled(Typography).attrs((props) => (
  { ...props, variant: (props.variant || 'h4') }))<TitleProps>`
  && {
    margin-bottom: ${(props) => ((props.gutterBottom && '3rem') || (props.bottomspacing ?? '0'))};

    font-family: 'Open Sans', sans-serif;
    font-size: ${(props) => (props.size ? props.size : '3.5rem')};
    font-weight: ${(props) => (props.weight ?? '400')};
    color: ${(props) => (props.fontcolor ?? props.theme.cores.primaria)};

    ${(props) => (props.secondary && `
      color: #000000;
      font-size: 2.8rem;
      font-weight: 600;
    `)}
  }
`;

interface TextProps {
  readonly fontcolor?: string;
  readonly opacity?: string;
  readonly weight?: string;
  readonly size?: string;
  readonly bottomspacing?: string;
  readonly topspacing?: string;
  readonly primary?: boolean;
}
export const Text = styled(Typography).attrs((props) => (
  { ...props, variant: (props.variant || 'body1') }))<TextProps>`
  && {
    margin-bottom: ${(props) => ((props.gutterBottom && '3rem') || (props.bottomspacing ?? '0'))};
    margin-top: ${(props) => (props.topspacing ?? '0')};
    width: 100%;

    font-family: 'Open Sans', sans-serif;
    font-size: ${(props) => (props.size ?? '2.3rem')};
    font-weight: ${(props) => (props.weight ?? '300')};
    color: ${(props) => (props.fontcolor ?? props.theme.cores.secundaria)};
    line-height: 1.3;

    opacity: ${(props) => (props.opacity ?? '1')};

    ${(props) => props.primary && `
      color: ${props.theme.cores.primaria};
    `};
  }
`;

export const HeaderGradient = styled.header`
  padding: var(--espacamento-m) var(--espacamento-lg);

  background: linear-gradient(45deg, #887334, #D5B14D)
`;

export const InputSuffix = styled(InputAdornment).attrs((props) => ({ position: props.position }))`
  && {
    position: absolute;
    right: 0;
    bottom: var(--espacamento-sm);

    p {
      opacity: 0.5;
      font-size: 1.4rem;
    }
  }
`;

interface ButtonLinkProps {
  readonly primary?: boolean;
  readonly size?: string;
}
export const ButtonLink = styled.button<ButtonLinkProps>`
  && {
    display: inline;
    padding: 0;
    vertical-align: baseline;

    appearance: none;

    font-size: ${(props) => (props.size ?? '1.6rem')};
    font-weight: 600;
    color: ${(props) => (props.primary ? props.theme.cores.primaria : '#FFFFFF')};
    background: transparent;
    text-decoration: underline;
    border: none;
    cursor: pointer;
  }
`;

import {
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';

const TIMEOUT_PADRAO = 800; // eslint-disable-next-line
function useDebounce<T extends (...args: any[]) => any>(callback: T, timeout: number = TIMEOUT_PADRAO) {
  const refChamada = useRef(-1);
  const [args, setArgs] = useState<any[]>([]);

  const chamar = useCallback((...argumentos) => setArgs(argumentos), []);

  useEffect(() => {
    window.clearTimeout(refChamada.current);
    refChamada.current = window.setTimeout(() => callback(...args), timeout);
    return () => window.clearTimeout(refChamada.current);
  }, [args]);

  return chamar;
}

export default useDebounce;

import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  ChangeEvent,
  memo,
} from 'react';

import { AppContext } from '../../../../contexts/AppContext';
import { DadosCadastraisContext } from '../../../../contexts/DadosCadastraisContext';

import MuiInput from '../../../../components/MuiInput';
import MuiButton from '../../../../components/MuiButton';
import MuiCheckbox from '../../../../components/MuiCheckbox';

import useRouter from '../../../../utils/hooks/useRouter';

function Celular() {
  const router = useRouter();
  const { appState, appDispatch } = useContext(AppContext);
  const { dadosCadastrais, dadosCadastraisDispatch } = useContext(DadosCadastraisContext);
  const [valido, setValido] = useState<boolean>(true);

  const { celular } = dadosCadastrais.contatos;

  /**
   * Valida se o celular digitado é válido ou não
   * @param {string} numero - Número digitado pelo usuário
   * @return {boolean} - Se número é válido ou não
   */
  const validaCelularDigitado = useCallback((numero: string): boolean => {
    const regex = /\([1-9][0-9]\)\s?[9]?\s?\d{4}([\s-])?\d{4}/;
    const resultado = regex.test(numero);

    if (numero !== null) {
      setValido(resultado);
    }

    return resultado;
  }, []);

  useEffect(() => {
    appDispatch({ type: 'MANIPULA_DISABLED', payload: !valido });
  }, [valido]);

  /**
   * Ao carregar componente altera a url anterior
   */
  useEffect(() => {
    appDispatch({ type: 'ALTERA_URL_ANTERIOR', payload: '/formulario/4/dados-pessoais/data-nascimento' });

    if (celular.numero === '') {
      appDispatch({ type: 'MANIPULA_DISABLED', payload: true });
    } else {
      validaCelularDigitado(celular.numero);
    }
  }, []);

  /**
   * Manipula dados digitados pelo usuario no campo de Celular
   * Se o Celular for válido grava dados no LocalStorage
   * @param {string} valor - Valor digitado pelo usuario
   */
  const manipulaInputCelular = useCallback((valor: string) => {
    let celularValido;
    const celularStorage = localStorage.getItem('dadosCadastrais');
    const celularTemp = celularStorage ? JSON.parse(celularStorage) : '';

    if (valor) {
      celularValido = validaCelularDigitado(valor);
    } else {
      dadosCadastraisDispatch({ type: 'ALTERA_CELULAR', payload: { ...celularTemp.contatos.celular, numero: '' } });
      appDispatch({ type: 'MANIPULA_DISABLED', payload: true });
      return;
    }

    if (celularValido) {
      dadosCadastraisDispatch({ type: 'ALTERA_CELULAR', payload: { ...celularTemp.contatos.celular, numero: valor } });
    }
  }, [celular]);

  /**
   * Manipula a checkbox para marcar se possui ou não whatsapp
   * Atualiza estado de celular conforme o ou deschecar o checkbox
   * @param {ChangeEvent<HTMLInputElement>} event - evento disparado ao usuario clicar na checkbox
   */
  const manipulaWhatsapp = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dadosCadastraisDispatch({ type: 'ALTERA_CELULAR', payload: { ...celular, whatsapp: event.target.checked } });
  }, [celular]);

  return (
    <>
      <MuiInput
        name="celular"
        label="Celular"
        validacao={valido}
        valorInicial={celular.numero}
        onChange={manipulaInputCelular}
        type="tel"
        inputMask="(99) 99999-9999"
      />
      <MuiCheckbox
        label="Este número tem Whatsapp"
        checado={celular.whatsapp}
        onChange={manipulaWhatsapp}
      />
      <MuiButton
        primary="true"
        fullWidth
        disabled={appState.disabled}
        onClick={() => router.push('/formulario/4/dados-pessoais/email')}
      >
        Continuar
      </MuiButton>
    </>
  );
}

export default memo(Celular);

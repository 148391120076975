import styled from 'styled-components';

import { Button } from '@material-ui/core';

import { darken } from 'polished';

interface ButtonProps {
  primary?: 'true';
  border?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  cores: any;
}

const ButtonStyled = styled(Button)<ButtonProps>`
  && {
    display: flex;
    flex-direction: row;
    justify-content: center;

    width: 100%;
    line-height: 1.3;

    font-family: 'Open Sans', sans-serif;
    font-size: 2.3rem;
    color: ${(props) => (props.cores.primaria)};
    text-transform: initial;

    background-color: transparent;

    ${(props) => props.primary && `
      max-width: ${props.fullWidth ? 'initial' : '300px'};
      padding: 2rem;

      font-size: 2.6rem;
      font-weight: 600;
      color: #FFFFFF;
      text-transform: uppercase;

      background-color: ${props.cores.primaria};
      box-shadow: var(--box-shadow);

      &.MuiButton-root:hover {
        background-color: ${darken(0.10, props.cores.primaria)};
      }
    `}

    ${(props) => props.disabled && `
      background-color: #d1d1d1;
    `}
    ${(props) => props.border && `
      border: 1px solid ${props.cores.primaria};
      padding: 2rem;
    `}
  }
`;

export default ButtonStyled;

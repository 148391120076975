import styled from 'styled-components';

import { FormControl, Select, MenuItem } from '@material-ui/core';

export const MenuStyled = styled(MenuItem)`
  && {
    font-size: 3rem;
  }
`;

export const SelectStyled = styled(Select)`
  && {
    .MuiSelect-root {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 8rem;
    }

    .MuiSelect-icon {
      width: 5rem;
      height: 5rem;
      top: calc(50% - 25px);
      color: #000000;
    }
  }
`;

const MuiFormControl = styled(FormControl)`
  && {
    margin: var(--espacamento-xlg) 0;
    width: 50%;

    .MuiInput-underline:before {
      border-bottom: 2px solid #444444;
    }

    .MuiInput-underline:after {
      border-bottom: 3px solid ${(props) => props.theme.cores.primaria}
    }
  }
`;

export default MuiFormControl;

import React, { ReactChild, memo } from 'react';

import StyledButton, { IconContainer, TextContainer } from './style';

interface SelectButtonProps {
  children?: ReactChild;
  href?: string;
  disabled?: boolean;
  destaque?: boolean;
  gutterBottom?: boolean;
  icone?: ReactChild;
  onClick?: () => void;
}

function SelectButton({
  children,
  href,
  disabled,
  icone,
  destaque,
  gutterBottom,
  onClick,
}: SelectButtonProps) {
  const TagType = href ? 'a' : 'button';
  const temIcone = !!icone;

  return (
    <StyledButton
      as={TagType}
      href={href}
      target={href && '_BLANK'}
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}
      destaque={destaque}
      gutterBottom={gutterBottom}
    >
      <IconContainer temIcone={temIcone}>
        {icone}
      </IconContainer>
      <TextContainer>
        {children}
      </TextContainer>
    </StyledButton>
  );
}

export default memo(SelectButton);

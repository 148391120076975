import {
  browserName,
  browserVersion,
  osName,
  osVersion,
  deviceType,
} from 'react-device-detect';

/**
 * Detecta dispositivo que está usando a aplicação
 * @return {string} - Dispositivo que está acessando a aplicação
 */
export default function detectaDispositivo(): string {
  const dispositivo = `${deviceType}, ${browserName} ${browserVersion}, ${osName} ${osVersion}`;

  return dispositivo;
}

export default function initWebcheckout(callback: Function) {
  const script = document.createElement('script');
  // script.async = true;
  script.crossOrigin = 'anonymous';
  script.src = 'https://assets.pagar.me/checkout/1.1.0/checkout.js';

  if (callback) {
    script.addEventListener('load', () => {
      callback();
    });
  }

  document.head.appendChild(script);
}

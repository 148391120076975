import styled from 'styled-components';

import { IconButton } from '@material-ui/core';

const ArrowBackButton = styled(IconButton)`
  && {
    padding: 0;

    color: #fff;

    opacity: 0.5;

    .MuiSvgIcon-root {
      width: 4rem;
      height: 4rem;
    }
  }
`;

export default ArrowBackButton;

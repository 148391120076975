import React, {
  useEffect,
  useCallback,
  useMemo,
  useContext,
  useState,
  memo,
} from 'react';
import { useHistory } from 'react-router-dom';

import { AppContext } from '../../../../contexts/AppContext';
import { ContribuicaoContext } from '../../../../contexts/ContribuicaoContext';

import RadioGroup from '../../../../components/RadioGroup';
import MuiButton from '../../../../components/MuiButton';
import Loading from '../../../../components/Loading';

import irParaNovaRota from '../../../../utils/irParaNovaRota';
import getMesAtual from '../../../../utils/getMesAtual';
import { Text, Title } from '../../../../styles/global';

type RadioItemsType = { tipo: string, label: string }[];

const initialValue: RadioItemsType = [
  {
    tipo: '',
    label: '',
  },
];

const data = new Date();
const getLabelMesAtual = getMesAtual(data.getMonth());
const getLabelProximoMes = getMesAtual((data.getMonth() === 11 ? 0 : data.getMonth() + 1));

function MesDaContribuicao() {
  const history = useHistory();
  const { appState, appDispatch } = useContext(AppContext);
  const { contribuicao, contribuicaoDispatch } = useContext(ContribuicaoContext);

  const [diaVencimentoEhMaiorQueAtual, setDiaVencimentoEhMaiorQueAtual] = useState(Boolean);
  const [dataAtual] = useState(new Date());
  const [diaAtual] = useState(dataAtual.getDate());
  const [mesesParaVencimento, setMesesParaVencimento] = useState(initialValue);

  const { carregando } = appState;
  const { textoMeioDePagamento, textoDiaDoVencimento } = appState.cabecalho.breadcrumbs;

  const breadcrumb = {
    textoMeioDePagamento,
    textoDiaDoVencimento,
    textoValorDaContribuicao: '',
    textoMesDeInicio: '',
  };

  const rotaAnterior = '/formulario/2/contribuicao/melhor-dia';
  const tituloContainer: string = diaVencimentoEhMaiorQueAtual ? 'Qual mês deseja iniciar as contribuições?' : 'Sua contribuição inicia em:';

  /**
   * Altera o estado dataInicio com o valor que o usuário escolher;
   * o valor é formatado para ISO Date
   * @param {string} mesEscolhido - Valor escolhido pelo usuário.
   */
  const alteraDataDeInicio = useCallback((mesEscolhido: string) => {
    if (contribuicao && contribuicao?.diaVencimento) {
      const dataInicio = dataAtual;
      dataInicio.setDate(contribuicao.diaVencimento);
      dataInicio.setMonth(Number(mesEscolhido));
      if (dataInicio < new Date()) {
        dataInicio.setFullYear(dataInicio.getFullYear() + 1);
      }
      const dataInicioFormatada = dataInicio.toISOString().substring(0, 10);

      contribuicaoDispatch({ type: 'ALTERA_MES_CONTRIBUICAO', payload: dataInicioFormatada });
    }
  }, []);

  /**
   * Recebe o valor inicial e formata o dado para mandar ao
   * componente RadioGroup
   */
  const parseInitialValue = useMemo((): string => {
    if (contribuicao && contribuicao.dataInicio) {
      const getMesInitialValue = +contribuicao?.dataInicio?.split('-')[1];
      return `${getMesInitialValue - 1}`;
    }

    return '';
  }, [contribuicao]);

  /**
   * Ao componente carregar atualiza o Cabecalho com a
   * url anterior, breadcrumbs e etapa atual
   * Faz a verificação se o diaVencimento é maior que o diaAtual
   * se for seta a variavel diaVencimentoEhMaiorQueAtual para true
   * e popula items com o mes atual e o próximo para exibir no RadioGroup
   * se não for set a variavel diaVencimentoEhMaiorQueAtual para false
   * e altera a data de inicio para o próximo mês.
   * Timeout para simular carregando e usuario não ver a troca da tela.
   */
  useEffect(() => {
    appDispatch({ type: 'MANIPULA_CARREGANDO', payload: true });
    appDispatch({ type: 'ALTERA_URL_ANTERIOR', payload: rotaAnterior });
    appDispatch({ type: 'ALTERA_BREADCRUMBS', payload: breadcrumb });
    appDispatch({ type: 'ALTERA_ETAPA_ATUAL', payload: 2 });

    if (contribuicao.diaVencimento && (contribuicao.diaVencimento >= diaAtual)) {
      setDiaVencimentoEhMaiorQueAtual(true);
      const items = [
        { tipo: (dataAtual.getMonth()).toString(), label: `${contribuicao.diaVencimento} de ${getLabelMesAtual}` },
        { tipo: ((dataAtual.getMonth() === 11 ? 0 : dataAtual.getMonth() + 1)).toString(), label: `${contribuicao.diaVencimento} de ${getLabelProximoMes}` },
      ];
      setMesesParaVencimento(items);
    } else {
      setDiaVencimentoEhMaiorQueAtual(false);
      const proximoMes = data.getMonth() + 1;
      alteraDataDeInicio(proximoMes.toString());
    }

    setTimeout(() => {
      appDispatch({ type: 'MANIPULA_CARREGANDO', payload: false });
    }, 400);
  }, []);

  useEffect(() => {
    if (contribuicao.dataInicio === '') {
      appDispatch({ type: 'MANIPULA_DISABLED', payload: true });
    } else {
      appDispatch({ type: 'MANIPULA_DISABLED', payload: false });
    }
  }, [contribuicao.dataInicio]);

  return (
    <>
      {carregando && <Loading full size={60} />}

      <Title secondary="true">
        {tituloContainer}
      </Title>
      {!carregando && diaVencimentoEhMaiorQueAtual && (
        <RadioGroup
          items={mesesParaVencimento}
          onChange={(e) => { alteraDataDeInicio(e); }}
          initialValue={parseInitialValue}
        />
      )}
      {!carregando && !diaVencimentoEhMaiorQueAtual && (
        <Text fontcolor="#444444" size="3rem" weight="700" gutterBottom>
          {contribuicao.diaVencimento} de {getLabelProximoMes}
        </Text>
      )}
      <MuiButton
        primary="true"
        fullWidth
        disabled={appState.disabled}
        onClick={() => {
          appDispatch({
            type: 'ALTERA_BREADCRUMBS',
            payload: {
              ...breadcrumb,
              textoMesDeInicio: getMesAtual(parseInt(parseInitialValue, 10)),
            },
          });
          irParaNovaRota(history, 'formulario/3/contribuicao/informa-valor');
        }}
      >
        Continuar
      </MuiButton>
    </>
  );
}

export default memo(MesDaContribuicao);

import styled from 'styled-components';

import { ArrowForwardIos } from '@material-ui/icons';

export const RecomendadoStyled = styled.span`
  display: block;
  padding: .5rem 1rem;
  width: calc(4rem * 4);
  margin-bottom: var(--espacamento-sm);

  text-align: center;
  font-size: 1.75rem;
  font-weight: 400;
  color: #FFFFFF;
  background: ${(props) => (props.theme.cores.primaria)};
  border-radius: 4px;
  box-shadow: var(--box-shadow);
`;

export const ArrowForwardIcon = styled(ArrowForwardIos)`
  && {
    align-self: center;
    justify-self: end;
    width: 7rem;
    height: 7rem;

    fill: ${(props) => (props.theme.cores.primaria)};

    opacity: .3;
  }
`;

const SelectButtonContributeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr .3fr;
  padding: var(--espacamento-lg);

  text-align: left;

  min-height: 170px;

  > div {
    margin: auto 0;
  }
`;

export default SelectButtonContributeContainer;

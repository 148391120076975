import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  memo,
} from 'react';
import { isValid } from 'cpf';

import { post } from '../../../../services/api';

import { AppContext } from '../../../../contexts/AppContext';
import { ContribuicaoContext } from '../../../../contexts/ContribuicaoContext';
import { DadosCadastraisContext } from '../../../../contexts/DadosCadastraisContext';

import MuiInput from '../../../../components/MuiInput';
import MuiButton from '../../../../components/MuiButton';
import Modal from '../../../../components/Modal';
import Loading from '../../../../components/Loading';
import ModalHelpContent from '../../../../components/ModalHelpContent';

import { Text, Title, ButtonLink } from '../../../../styles/global';

import useModal from '../../../../utils/hooks/useModal';
import useRouter from '../../../../utils/hooks/useRouter';

function CadastroCPF() {
  const router = useRouter();
  const { appState, appDispatch } = useContext(AppContext);
  const { contribuicao } = useContext(ContribuicaoContext);
  const { dadosCadastrais, dadosCadastraisDispatch } = useContext(DadosCadastraisContext);
  const {
    textoMeioDePagamento,
    textoDiaDoVencimento,
    textoValorDaContribuicao,
    textoMesDeInicio,
  } = appState.cabecalho.breadcrumbs;

  const breadcrumb = {
    textoMeioDePagamento,
    textoDiaDoVencimento,
    textoValorDaContribuicao,
    textoMesDeInicio,
  };

  const [valido, setValido] = useState<boolean>(true);
  const [erro, setErro] = useState<string | null>(null);
  const [abreModalCpf, toggleModalCpf] = useModal();
  const [abreModalHelp, toggleModalHelp] = useModal();
  /**
   * Ao carregar componente atualizar a url anterior, os breadcrumbs e a etapa atual
   */
  useEffect(() => {
    appDispatch({ type: 'ALTERA_URL_ANTERIOR', payload: '/formulario/3/contribuicao/informa-valor' });
    appDispatch({ type: 'ALTERA_BREADCRUMBS', payload: breadcrumb });
    appDispatch({ type: 'ALTERA_ETAPA_ATUAL', payload: 4 });

    if (dadosCadastrais.dados.cpf !== '') {
      setValido(isValid(dadosCadastrais.dados.cpf));
    }
  }, []);

  useEffect(() => {
    appDispatch({ type: 'MANIPULA_DISABLED', payload: !valido });
  }, [valido]);

  /**
   * Manipula o campo de input validando se o CPF é válido ou não
   * Caso seja válido atualiza o estado cpf dos dados cadastrais
   * @param {string} valor - Valor digitado pelo usuário
   */
  const manipulaInputCpf = useCallback((valor: string) => {
    let validaCpf;
    setErro(null);

    if (valor) {
      validaCpf = isValid(valor);
    } else {
      dadosCadastraisDispatch({ type: 'ALTERA_CPF', payload: '' });
      appDispatch({ type: 'MANIPULA_DISABLED', payload: true });
      return;
    }

    if (!validaCpf) {
      setErro('CPF Inválido');
    } else {
      setErro(null);
    }

    setValido(validaCpf);

    if (validaCpf) {
      dadosCadastraisDispatch({ type: 'ALTERA_CPF', payload: valor.trim() });
    }
  }, []);

  /**
   * Verifica se o CPF é ou não cooperador
   * Faz chamada ao endpoint verifica-cpf
   * Caso o usuario tenha selecionado o tipo de contribuição mensal o endpoint pode retornar
   * cooperador true - então abre a modal dizendo que o CPF já é cooperador, se retornar false
   * prossegue para a próxima etapa. Caso seja contribuição única ele avança direto para a próxima
   * etapa. Em ambos os casos, se retornar 400, é exibido uma mensagem de CPF inválido
   */
  const verificaCpf = useCallback(async () => {
    appDispatch({ type: 'MANIPULA_CARREGANDO', payload: true });
    // Remove pontos e traço do cpf
    const formataCpf = dadosCadastrais.dados.cpf.replace(/[^0-9]+/g, '');
    try {
      const retorno = await post('api-contribua', '/verifica-cpf', { cpf: formataCpf });

      if (retorno) {
        dadosCadastraisDispatch({ type: 'ALTERA_IDPESSOA', payload: retorno.data.idPessoa });
        dadosCadastraisDispatch({ type: 'ALTERA_COOPERADOR', payload: retorno.data.cooperador });
        // se contribuição for 'mensal'
        if (contribuicao.tipo === 2) {
          if (!retorno.data.cooperador) {
            router.push('/formulario/4/dados-pessoais/nome');
          } else {
            toggleModalCpf();
          }
        } else {
          router.push('/formulario/4/dados-pessoais/nome');
        }
      }
    } catch (error) {
      if (error.status === 400) {
        setValido(false);
        setErro(error.data.message);
      }
    } finally {
      appDispatch({ type: 'MANIPULA_CARREGANDO', payload: false });
    }
  }, [dadosCadastrais, post]);

  return (
    <>
      <MuiInput
        name="cpf"
        label="Informe o seu CPF"
        type="tel"
        inputMask="999.999.999-99"
        validacao={valido}
        helperText={erro}
        gutterbottom="true"
        valorInicial={dadosCadastrais.dados.cpf}
        onChange={manipulaInputCpf}
      />
      <MuiButton
        primary="true"
        fullWidth
        disabled={appState.disabled}
        onClick={verificaCpf}
      >
        Continuar
      </MuiButton>

      {appState.carregando && <Loading text="Aguarde um momento... Validando CPF." full size={60} />}

      <Modal
        exibe={abreModalCpf}
        toggle={toggleModalCpf}
        cabecalho={<Title size="2.5rem" fontcolor="#FFFFFF" style={{ marginBottom: 'var(--espacamento-xsm)' }}>Aviso</Title>}
        conteudo={(
          <>
            <Text size="2rem" weight="600" fontcolor="#444444" bottomspacing="2rem">O seu CPF já está ativo como cooperador.</Text>
            <Text size="2rem" fontcolor="#444444">
              Caso deseje alterar sua contribuição entre em&nbsp;
              <ButtonLink
                primary
                size="2rem"
                onClick={() => {
                  toggleModalCpf();
                  toggleModalHelp();
                }}
              >
                contato conosco.
              </ButtonLink>
            </Text>
          </>
        )}
      />
      <Modal
        exibe={abreModalHelp}
        toggle={toggleModalHelp}
        cabecalho={<Title size="2.5rem" fontcolor="#FFFFFF">Fale Conosco</Title>}
        conteudo={<ModalHelpContent />}
      />
    </>
  );
}

export default memo(CadastroCPF);

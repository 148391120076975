import React, {
  useContext,
  useMemo,
  memo,
  useCallback,
} from 'react';
import { Switch } from 'react-router-dom';
import { Method } from 'axios';

import returnPromiseApi from '../../services/returnPromiseApi';

import { AppContext } from '../../contexts/AppContext';
import { ContribuicaoContext, ContribuicaoState } from '../../contexts/ContribuicaoContext';
import { DadosCadastraisContext, IContatosState, IDadosState } from '../../contexts/DadosCadastraisContext';
import { RouteWithSubRoutes, NoMatchRoute } from '../../routes';

import ProgressBar from '../../components/ProgressBar';
import Header from '../../components/Header';
import HelpContainer from '../../components/HelpContainer';

import rotasContribuicao from './Contribuicao/routes';
import rotasDadosPessoais from './DadosPessoais/routes';
import Termo from './Termo';

import useUnload from '../../utils/hooks/useUnload';
import useRouter from '../../utils/hooks/useRouter';
import getUrlApi from '../../services/url';

interface ITentativaContribuicao {
  tela: string,
  idPessoa: number | null,
  contribuicao: ContribuicaoState,
  pessoa: {
    dados: IDadosState,
    contatos: IContatosState
  },
}

function Form() {
  const router = useRouter();
  const { match, pathname } = router;
  const { appState } = useContext(AppContext);
  const { contribuicao } = useContext(ContribuicaoContext);
  const { dadosCadastrais } = useContext(DadosCadastraisContext);

  const {
    etapa,
    urlAnterior,
    titulo,
    breadcrumbs,
  } = appState.cabecalho;

  const { cpf, nome, dataNascimento } = dadosCadastrais.dados;

  const tituloCentralizado = useMemo(() => {
    if (etapa === 1) return 'true';
    return undefined;
  }, [etapa]);

  const rotas = useMemo(() => ([
    ...rotasContribuicao,
    ...rotasDadosPessoais,
    {
      path: `${match.path}/5/termo`,
      component: Termo,
    },
    {
      path: `${match.path}/`,
      component: NoMatchRoute,
    },
  ]), [match.path]);

  /**
   * Realiza um post na api-contribua com uma tentativa de contribuicao
   */
  const tentativaDeContribuicao = useCallback(() => {
    const body: ITentativaContribuicao = {
      tela: pathname,
      idPessoa: dadosCadastrais.idPessoa,
      contribuicao,
      pessoa: {
        dados: {
          cpf,
          nome,
          dataNascimento,
        },
        contatos: dadosCadastrais.contatos,
      },
    };

    navigator.sendBeacon(`${getUrlApi('api-contribua')}/contribuicoes/tentativas`, JSON.stringify(body));

    const metodo: Method = 'POST';

    const params = {
      apiName: 'api-contribua',
      metodo,
      url: '/contribuicoes/tentativas',
      body: JSON.stringify(body),
    };

    returnPromiseApi(params);
  }, []);

  /**
   * Ao usuario sair da aplicação, verifica se cpf está preenchido
   * caso esteja tenta salvar uma Tentativa de Contribuição
  */
  useUnload((e: BeforeUnloadEvent) => {
    e.preventDefault();

    if (!cpf.length) {
      return;
    }

    tentativaDeContribuicao();
  });
  return (
    <>
      <ProgressBar etapa={etapa} />
      <Header
        titulo={titulo}
        breadcrumbs={breadcrumbs}
        centralizado={tituloCentralizado}
        goBack={urlAnterior}
      />
      <HelpContainer>
        <Switch>
          {rotas.map((rota) => <RouteWithSubRoutes key={rota.path} {...rota} />)}
        </Switch>
      </HelpContainer>
    </>
  );
}

export default memo(Form);

import React, {
  createContext,
  Dispatch,
} from 'react';

import useReducerPersiste from '../utils/hooks/useReducerPersiste';
import { WithChildren } from '../interfaces';

interface IActionCPF {
  type: 'ALTERA_CPF',
  payload: string
}
interface IActionIdPessoa {
  type: 'ALTERA_IDPESSOA',
  payload: number | null
}
interface IActionCooperador {
  type: 'ALTERA_COOPERADOR',
  payload: boolean | null
}
interface IActionNome {
  type: 'ALTERA_NOME',
  payload: string
}
interface IActionNascimento {
  type: 'ALTERA_DATA_NASCIMENTO',
  payload: string
}
interface IActionCelular {
  type: 'ALTERA_CELULAR',
  payload: ICelularState
}
interface IActionEmail {
  type: 'ALTERA_EMAIL',
  payload: IEmailState
}
interface IActionCep {
  type: 'ALTERA_ENDERECO_CEP',
  cep: string,
}
interface IActionEstado {
  type: 'ALTERA_ENDERECO_ESTADO',
  estado: string,
}
interface IActionCidade {
  type: 'ALTERA_ENDERECO_CIDADE',
  cidade: string,
  idCidade: number
}
interface IActionBairro {
  type: 'ALTERA_ENDERECO_BAIRRO',
  bairro: string,
  idBairro: number,
}
interface IActionLogradouro {
  type: 'ALTERA_ENDERECO_LOGRADOURO',
  logradouro: string,
  idLogradouro: number
}
interface IActionNumero {
  type: 'ALTERA_ENDERECO_NUMERO',
  numero: string | null,
}
interface IActionComplemento {
  type: 'ALTERA_ENDERECO_COMPLEMENTO',
  complemento: string | null,
}
interface IActionTermos {
  type: 'ALTERA_TERMOS',
  termos: ITermo[],
}
interface IActionReset {
  type: 'RESET_DADOS'
}

type EnderecoActions = IActionCep
| IActionEstado
| IActionCidade
| IActionBairro
| IActionLogradouro
| IActionNumero
| IActionComplemento;

type DadosCadastraisAction = IActionCPF
  | IActionIdPessoa
  | IActionCooperador
  | IActionNome
  | IActionNascimento
  | IActionCelular
  | IActionEmail
  | EnderecoActions
  | IActionTermos
  | IActionReset;

interface ICelularState {
  tipo: number,
  numero: string,
  whatsapp: boolean,
}
interface IEmailState {
  tipo?: number | null,
  endereco?: string | null,
  naoPossui?: boolean,
}
export interface IContatosState {
  celular: ICelularState,
  email?: IEmailState
}

export interface IEnderecoState {
  tipo: number,
  cep: string,
  estado: string,
  idCidade: string,
  cidade: string
  idBairro: string,
  bairro: string,
  idLogradouro: string,
  logradouro: string,
  numero: string | null,
  complemento: string | null,
}
export interface IDadosState {
  cpf: string,
  nome: string,
  dataNascimento: string,
}

export interface ITermo {
  idTermo: number,
  idTermoTipo: number,
  aceito: boolean,
  dispositivo: string,
}

export interface DadosCadastraisState {
  idPessoa: number | null,
  cooperador: boolean | null,
  app: string,
  dados: IDadosState,
  contatos: IContatosState,
  endereco: IEnderecoState,
  termos: ITermo[],
}

type ContextData = {
  dadosCadastrais: DadosCadastraisState;
  dadosCadastraisDispatch: Dispatch<DadosCadastraisAction>;
}
const valorInicialDoContexto: ContextData = {
  dadosCadastrais: {
    idPessoa: null,
    cooperador: null,
    app: 'App Contribua',
    dados: {
      cpf: '',
      nome: '',
      dataNascimento: '',
    },
    contatos: {
      celular: {
        tipo: 4,
        numero: '',
        whatsapp: true,
      },
      email: {
        tipo: null,
        endereco: '',
        naoPossui: false,
      },
    },
    endereco: {
      tipo: 1,
      cep: '',
      estado: '',
      idCidade: '',
      cidade: '',
      idBairro: '',
      bairro: '',
      idLogradouro: '',
      logradouro: '',
      numero: '',
      complemento: '',
    },
    termos: [],
  },
  dadosCadastraisDispatch: (value) => {},
};

export const DadosCadastraisContext = createContext(valorInicialDoContexto);

// Taken from https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#union-exhaustiveness-checking
function assertNever(x: never): never {
  throw new Error(`Unexpected object: ${x}`);
}

const dadosCadastraisReducer = (state: DadosCadastraisState, action: DadosCadastraisAction) => {
  switch (action.type) {
    case 'ALTERA_CPF':
      return {
        ...state,
        dados: {
          ...state.dados,
          cpf: action.payload,
        },
      };

    case 'ALTERA_IDPESSOA':
      return { ...state, idPessoa: action.payload };

    case 'ALTERA_COOPERADOR':
      return {
        ...state,
        cooperador: action.payload,
      };

    case 'ALTERA_NOME':
      return {
        ...state,
        dados: {
          ...state.dados,
          nome: action.payload,
        },
      };

    case 'ALTERA_DATA_NASCIMENTO':
      return {
        ...state,
        dados: {
          ...state.dados,
          dataNascimento: action.payload,
        },
      };

    case 'ALTERA_CELULAR':
      return {
        ...state,
        contatos: {
          ...state.contatos,
          celular: action.payload,
        },
      };

    case 'ALTERA_EMAIL':
      return {
        ...state,
        contatos: {
          ...state.contatos,
          email: action.payload,
        },
      };

    case 'ALTERA_ENDERECO_CEP':
      return {
        ...state,
        endereco: {
          ...state.endereco,
          cep: action.cep,
        },
      };

    case 'ALTERA_ENDERECO_ESTADO':
      return {
        ...state,
        endereco: {
          ...state.endereco,
          estado: action.estado,
        },
      };
    case 'ALTERA_ENDERECO_CIDADE':
      return {
        ...state,
        endereco: {
          ...state.endereco,
          cidade: action.cidade,
          idCidade: action.idCidade,
        },
      };
    case 'ALTERA_ENDERECO_BAIRRO':
      return {
        ...state,
        endereco: {
          ...state.endereco,
          bairro: action.bairro,
          idBairro: action.idBairro,
        },
      };
    case 'ALTERA_ENDERECO_LOGRADOURO':
      return {
        ...state,
        endereco: {
          ...state.endereco,
          logradouro: action.logradouro,
          idLogradouro: action.idLogradouro,
        },
      };
    case 'ALTERA_ENDERECO_NUMERO':
      return {
        ...state,
        endereco: {
          ...state.endereco,
          numero: action.numero,
        },
      };
    case 'ALTERA_ENDERECO_COMPLEMENTO':
      return {
        ...state,
        endereco: {
          ...state.endereco,
          complemento: action.complemento,
        },
      };

    case 'ALTERA_TERMOS':
      return {
        ...state,
        termos: action.termos,
      };

    case 'RESET_DADOS':
      return valorInicialDoContexto.dadosCadastrais;

    default:
      return assertNever(action);
  }
};

function DadosCadastraisProvider({ children }: WithChildren) {
  const [dadosCadastrais, dadosCadastraisDispatch] = useReducerPersiste(
    'dadosCadastrais',
    dadosCadastraisReducer,
    valorInicialDoContexto.dadosCadastrais as never,
  );

  return (
    <DadosCadastraisContext.Provider
      value={{
        dadosCadastrais,
        dadosCadastraisDispatch,
      }}
    >
      {children}
    </DadosCadastraisContext.Provider>
  );
}

export default DadosCadastraisProvider;

import styled from 'styled-components';

import { IconButton } from '@material-ui/core';

const IconButtonContainer = styled(IconButton)`
  && {
    position: fixed;
    right: var(--espacamento-sm);
    bottom: var(--espacamento-sm);

    background: linear-gradient(320deg, #000000, #4A3800);

    svg {
      width: 5rem;
      height: 5rem;

      fill: #FFFFFF;
    }
  }
`;

export default IconButtonContainer;

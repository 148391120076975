import React, {
  createContext,
  Dispatch,
} from 'react';

import useReducerPersiste from '../utils/hooks/useReducerPersiste';
import { WithChildren } from '../interfaces';

interface IActionFormaDeContribuicao {
  type: 'ALTERA_FORMA_DE_CONTRIBUICAO',
  payload: number
}
interface IActionMeioDePagamento {
  type: 'ALTERA_MEIO_DE_PAGAMENTO',
  payload: number
}
interface IActionValorDaContribuicao {
  type: 'ALTERA_VALOR_CONTRIBUICAO',
  payload: number
}
interface IActionDiaDeVencimento {
  type: 'ALTERA_DIA_VENCIMENTO',
  payload: number | null
}
interface IActionMesDaContribuicao {
  type: 'ALTERA_MES_CONTRIBUICAO',
  payload: string | null
}
interface IActionReset {
  type: 'RESET_DADOS'
}

type ContribuicaoAction = IActionFormaDeContribuicao
  | IActionMeioDePagamento
  | IActionValorDaContribuicao
  | IActionDiaDeVencimento
  | IActionMesDaContribuicao
  | IActionReset;

export interface ContribuicaoState {
  tipo: number,
  meioDePagamento: number,
  valorContribuicao: number,
  diaVencimento: number | null,
  dataInicio: string | null
}

type ContextData = {
  contribuicao: ContribuicaoState;
  contribuicaoDispatch: Dispatch<ContribuicaoAction>;
}
const valorInicialDoContexto: ContextData = {
  contribuicao: {
    tipo: 0,
    meioDePagamento: 0,
    valorContribuicao: 0,
    diaVencimento: null,
    dataInicio: null,
  },
  contribuicaoDispatch: (value) => {},
};

export const ContribuicaoContext = createContext(valorInicialDoContexto);

const contribuicaoReducer = (
  state: ContribuicaoState, action: ContribuicaoAction,
): ContribuicaoState => {
  switch (action.type) {
    case 'ALTERA_FORMA_DE_CONTRIBUICAO':
      if (action.payload === 2) {
        return { ...state, tipo: action.payload };
      }
      return {
        ...state,
        tipo: action.payload,
        diaVencimento: null,
        dataInicio: null,
      };

    case 'ALTERA_MEIO_DE_PAGAMENTO':
      return { ...state, meioDePagamento: action.payload };

    case 'ALTERA_DIA_VENCIMENTO':
      return { ...state, diaVencimento: action.payload };

    case 'ALTERA_MES_CONTRIBUICAO':
      return { ...state, dataInicio: action.payload };

    case 'ALTERA_VALOR_CONTRIBUICAO':
      return { ...state, valorContribuicao: action.payload };

    case 'RESET_DADOS':
      return valorInicialDoContexto.contribuicao;

    default:
      return { ...state };
  }
};

function ContribuicaoContextProvider({ children }: WithChildren) {
  const [contribuicao, contribuicaoDispatch] = useReducerPersiste(
    'contribuicao',
    contribuicaoReducer,
    valorInicialDoContexto.contribuicao,
  );

  return (
    <ContribuicaoContext.Provider
      value={{
        contribuicao,
        contribuicaoDispatch,
      }}
    >
      {children}
    </ContribuicaoContext.Provider>
  );
}

export default ContribuicaoContextProvider;

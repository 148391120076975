import React, { memo } from 'react';

import Modal from '../../../components/Modal';

import {
  HeaderGradient,
  Text,
  Title,
  ButtonLink,
} from '../../../styles/global';

import useModal from '../../../utils/hooks/useModal';

import { ITermoProps } from '../../Home';

import ConteudoLGPD from './styles';

interface ILGPDProps {
  termos: Omit<ITermoProps, 'dispositivo' | 'aceito'>[];
}

function LGPD({ termos }: ILGPDProps) {
  const [abreLGPD, toggleLGPD] = useModal();
  const [abreTermoUso, toggleTermoUso] = useModal();

  return (
    <>
      <HeaderGradient style={{ padding: '2rem 3rem' }}>
        <Text fontcolor="#FFFFFF" size="1.6rem" style={{ maxWidth: '600px', margin: '0 auto' }}>
          Usamos cookies em nosso site para melhorar a sua experiência.
          Ao continuar navegando, você concorda com o uso de cookies,
          nossa <ButtonLink onClick={() => toggleLGPD()}>Política de Privacidade</ButtonLink>
          &nbsp; e <ButtonLink onClick={() => toggleTermoUso()}>Termo de uso</ButtonLink>.
        </Text>
      </HeaderGradient>
      {termos.map((termo) => (
        <Modal
          exibe={termo.idTermoTipo === 1 ? abreLGPD : abreTermoUso}
          toggle={termo.idTermoTipo === 1 ? toggleLGPD : toggleTermoUso}
          cabecalho={(
            <Title
              size="2.5rem"
              fontcolor="#FFFFFF"
            >
              {termo.titulo}
            </Title>
          )}
          conteudo={(
            <ConteudoLGPD dangerouslySetInnerHTML={{ __html: `<div> ${termo.texto} </div>` }} />
          )}
        />
      ))}
    </>
  );
}

export default memo(LGPD);

import styled from 'styled-components';

interface StyledButtonProps {
  href?: string;
  target?: string;
  destaque?: boolean;
  gutterBottom?: boolean;
}

interface IconeProps {
  temIcone?: boolean;
}

export const TextContainer = styled.div`
  padding-left: var(--espacamento-sm);

  font-size: 2rem;
`;

export const IconContainer = styled.div<IconeProps>`
  min-width: .5rem;
  height: 100%;

  background: #D7B24C;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  color: #fff;

  ${(props) => props.temIcone && `
    padding: var(--espacamento-sm);

    svg {
      width: 3rem;
      height: 3rem;
    }
  `}
`;

const StyledButton = styled.div<StyledButtonProps>`
  display: flex;
  align-items: center;

  margin-bottom: ${(props) => (props.gutterBottom ? 'var(--espacamento-m)' : 'var(--espacamento-xsm)')};

  border: 0;
  border-radius: var(--border-radius);

  background: linear-gradient(180deg, #fcfbf7, #F8F5EC);
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #444444;
  text-decoration: none;
  appearance: none;

  box-shadow: var(--box-shadow);
  transition: 200ms linear;

  &:hover,
  &:active {
    background: linear-gradient(180deg, #f7f3e8, #f3dfac);

    cursor: pointer;
    transition: 200ms linear;
  }

  ${(props) => (props.destaque && `
    background: linear-gradient(180deg, #f7f3e8, #f3dfac);

    &:hover,
    &.active {
      opacity: .8;
    }
  `)}
`;

export default StyledButton;

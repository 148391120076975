import React, { ReactChild, useContext, memo } from 'react';
import { ThemeContext } from 'styled-components';
import { ButtonProps } from '@material-ui/core/Button';

import ButtonStyled from './style';

interface Props extends ButtonProps {
  children?: ReactChild;
  primary?: 'true';
  border?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
}

function MuiButton({
  children,
  primary,
  border,
  startIcon,
  disabled,
  fullWidth,
  onClick,
}: Props) {
  const { cores } = useContext(ThemeContext);

  return (
    <ButtonStyled
      fullWidth={fullWidth}
      disabled={disabled}
      primary={primary}
      border={border}
      cores={cores}
      startIcon={startIcon}
      onClick={() => {
        if ((!disabled) && onClick) {
          onClick();
        }
      }}
    >
      {children}
    </ButtonStyled>
  );
}

export default memo(MuiButton);

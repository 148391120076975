import styled from 'styled-components';

import { Dialog, IconButton } from '@material-ui/core';
import { HeaderGradient } from '../../styles/global';

export const CloseButton = styled(IconButton)`
  && {
    color: #FFF;
    padding: 0;

    .MuiSvgIcon-root {
      width: 4rem;
      height: 4rem;
    }
  }
`;

export const Header = styled(HeaderGradient)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled(Dialog)`
  && {
    .MuiPaper-root {
      border-radius: 3px;

      width: 100vmin;

      .MuiDialogContent-root {
        padding: var(--espacamento-lg);
      }
    }

    .MuiBackdrop-root {
      background-color: rgba(0,0,0,0.85);
    }
  }
`;

export default Container;

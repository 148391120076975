import React, {
  memo,
  ReactElement,
} from 'react';

import { DialogContent } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import Container, { Header, CloseButton } from './style';

interface ModalProps {
  exibe: boolean;
  toggle: () => void;
  conteudo?: ReactElement;
  cabecalho?: ReactElement;
}

function Modal({
  exibe,
  toggle,
  cabecalho,
  conteudo,
}: ModalProps) {
  return (
    <Container
      open={exibe}
      onClose={() => toggle()}
      maxWidth="xs"
    >
      <Header>
        <CloseButton onClick={() => toggle()}>
          <CloseIcon />
        </CloseButton>
        {cabecalho}
      </Header>
      <DialogContent>
        {conteudo}
      </DialogContent>
    </Container>
  );
}

export default memo(Modal);

import React, { useState, useCallback, memo } from 'react';

import {
  FormControlLabel,
  RadioGroup as RadioContainer,
  Divider,
} from '@material-ui/core';

import { Text } from '../../styles/global';

import FormGroupContainer, { RadioButton } from './styles';

type ItemsType = {
  items:{
    tipo: string | number,
    label: string,
  }[] | null;
  initialValue?: string | number;
  onChange: (value: string) => void;
};

function RadioGroup({ items, initialValue, onChange }: ItemsType) {
  const [checado, setChecado] = useState(initialValue);
  const manipulaRadio = useCallback((e) => {
    setChecado(e.target.value);
    onChange(e.target.value);
  }, []);

  return (
    <FormGroupContainer>
      <RadioContainer
        value={checado}
        onChange={manipulaRadio}
      >
        {items?.map((item: any, i: number) => (
          <div key={item.label}>
            <FormControlLabel
              control={(
                <RadioButton
                  name={item.tipo}
                  value={item.tipo}
                />
              )}
              label={(
                <Text weight="500">
                  {item.label}
                </Text>
              )}
            />
            {i === 0 && (<Divider />)}
          </div>
        ))}
      </RadioContainer>
    </FormGroupContainer>
  );
}

export default memo(RadioGroup);

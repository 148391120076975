import React, {
  memo,
  useEffect,
  useState,
} from 'react';

import ProgressBarContainer, { MuiLinearProgress, Stage } from './styles';

interface etapaProps {
  etapa: number;
}

function ProgressBar({ etapa }: etapaProps) {
  const [porcentagem, setPorcentagem] = useState(Number);
  const etapaMaxima = 5;

  useEffect(() => {
    const porcentagemMaxima = 100 / etapaMaxima;

    setTimeout(() => {
      setPorcentagem(porcentagemMaxima * etapa);
    }, 200);
  }, [etapa]);

  return (
    <ProgressBarContainer>
      <MuiLinearProgress
        variant="determinate"
        value={porcentagem}
      />

      <Stage fontcolor={etapa > 2 ? '#000' : '#FFF'} opacity="0.6" size="1.75rem">
        {etapa} de {etapaMaxima}
      </Stage>
    </ProgressBarContainer>
  );
}

export default memo(ProgressBar);

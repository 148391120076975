/**
 * Retorna o mês atual
 * @param {number} - index obtido pelo método 'getMonth()'
 * @reutn {string} - label do mês atual
 */
export default function getMesAtual(indice: number): string {
  const meses = [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro',
  ];

  return meses[indice];
}

import getEnviroment from '../utils/getEnviroment';

interface ApiProps {
  [api: string]: {
    [enviroment: string]: string;
  };
}

const API: ApiProps = {
  'api-contribua': {
    // local: 'http://localhost:3000',
    local: 'https://api-contribua-develop.rj.r.appspot.com',
    develop: 'https://api-contribua-develop.rj.r.appspot.com',
    release: 'https://api-contribua-release.rj.r.appspot.com',
    hotfix: 'https://api-contribua-release.rj.r.appspot.com',
    production: 'https://api-contribua-production.rj.r.appspot.com',
  },
  'api-enderecos': {
    local: 'https://api-enderecos-develop.rj.r.appspot.com',
    develop: 'https://api-enderecos-develop.rj.r.appspot.com',
    release: 'https://api-enderecos-release.rj.r.appspot.com',
    hotfix: 'https://api-enderecos-release.rj.r.appspot.com',
    production: 'https://api-enderecos-production.rj.r.appspot.com',
  },
  'api-pessoas': {
    local: 'https://api-pessoas-develop.rj.r.appspot.com',
    develop: 'https://api-pessoas-develop.rj.r.appspot.com',
    release: 'https://api-pessoas-release.rj.r.appspot.com',
    hotfix: 'https://api-pessoas-release.rj.r.appspot.com',
    production: 'https://api-pessoas-production.rj.r.appspot.com',
  },
  'api-mock': {
    local: 'https://run.mocky.io/v3/',
    develop: 'https://run.mocky.io/v3/',
  },
};

export default function getUrlApi(api: string) {
  const enviroment = getEnviroment();

  return API[api][enviroment];
}

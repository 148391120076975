import React, {
  useCallback,
  useContext,
  useEffect,
  memo,
} from 'react';
import { useHistory } from 'react-router-dom';

import { AppContext } from '../../../../contexts/AppContext';
import { ContribuicaoContext } from '../../../../contexts/ContribuicaoContext';

import ValueButtonToContribute from './components/ValueButtonToContribute';
import irParaNovaRota from '../../../../utils/irParaNovaRota';
import { Title } from '../../../../styles/global';

const valores = [
  50, 100, 150, 200, 250, 300,
];

function EscolhaDeValores() {
  const history = useHistory();
  const { appState, appDispatch } = useContext(AppContext);
  const { contribuicao, contribuicaoDispatch } = useContext(ContribuicaoContext);
  const {
    textoMeioDePagamento,
    textoDiaDoVencimento,
    textoMesDeInicio,
  } = appState.cabecalho.breadcrumbs;

  const rotaAnterior = contribuicao.tipo === 2 ? '/formulario/2/contribuicao/mes-escolhido' : '/formulario/2/contribuicao/meio-de-pagamento';

  const breadcrumb = {
    textoMeioDePagamento,
    textoMesDeInicio,
    textoDiaDoVencimento,
    textoValorDaContribuicao: '',
  };

  /**
   * Altera o estado valorContribuicao com o valor que o usuário escolher;
   * Caso o usuario selecione o item 'Outro Valor' redireciona para a respectiva página.
   * Caso selecione qualquer um dos outros valores, atualiza a contribuicao e o cabecalho.
   * @param {number} item - Valor escolhido pelo usuário.
   */
  const selecionaValorDesejado = useCallback((item: number) => {
    if (item === 0) {
      irParaNovaRota(history, 'formulario/3/contribuicao/outro-valor');
    } else {
      contribuicaoDispatch({ type: 'ALTERA_VALOR_CONTRIBUICAO', payload: item });

      let valorFinalFormatado: string;
      if (Number.isInteger(item)) {
        valorFinalFormatado = `${item},00`;
      } else {
        valorFinalFormatado = `${item.toString().replace('.', ',')}`;
      }

      appDispatch({
        type: 'ALTERA_BREADCRUMBS',
        payload: {
          ...breadcrumb,
          textoValorDaContribuicao: `R$ ${valorFinalFormatado}`,
        },
      });

      irParaNovaRota(history, 'formulario/4/dados-pessoais/cpf');
    }
  }, []);

  /**
   * Altera o estado dataInicio com o valor que o usuário escolher;
   * o valor é formatado para ISO Date
   * Atualiza o cabecalho
   */
  useEffect(() => {
    appDispatch({ type: 'ALTERA_URL_ANTERIOR', payload: rotaAnterior });
    appDispatch({ type: 'ALTERA_BREADCRUMBS', payload: breadcrumb });
    appDispatch({ type: 'ALTERA_ETAPA_ATUAL', payload: 3 });
  }, []);

  return (
    <>
      <Title secondary="true">
        Com qual valor pode ajudar?
      </Title>
      <ValueButtonToContribute
        items={valores}
        onSelect={(item: number) => selecionaValorDesejado(item)}
      />
    </>
  );
}

export default memo(EscolhaDeValores);

import React, { createContext, Dispatch } from 'react';

import useReducerPersiste from '../utils/hooks/useReducerPersiste';
import { WithChildren } from '../interfaces';

interface IStateBoleto {
  boletoURL: string,
  boletoBarcode: string,
  boletoExpirationDate: string,
}

interface IActionBoleto {
  type: 'ALTERA_BOLETO',
  payload: IStateBoleto,
}

interface IActionReset {
  type: 'RESET_BOLETO',
}

type ActionBoleto = IActionBoleto | IActionReset;

type BoletoContextData = {
  boletoState: IStateBoleto;
  boletoDispatch: Dispatch<ActionBoleto>
}

const valorInicial: BoletoContextData = {
  boletoState: {
    boletoURL: '',
    boletoBarcode: '',
    boletoExpirationDate: '',
  },
  boletoDispatch: (value) => {},
};

export const BoletoContext = createContext(valorInicial);

const appReducer = (state: IStateBoleto, action: ActionBoleto): IStateBoleto => {
  switch (action.type) {
    case 'ALTERA_BOLETO':
      return action.payload;

    case 'RESET_BOLETO':
      return valorInicial.boletoState;

    default:
      return state;
  }
};

function BoletoContextProvider({ children }: WithChildren) {
  const [boletoState, boletoDispatch] = useReducerPersiste('boleto', appReducer, valorInicial.boletoState);

  return (
    <BoletoContext.Provider
      value={{
        boletoState,
        boletoDispatch,
      }}
    >
      {children}
    </BoletoContext.Provider>
  );
}

export default BoletoContextProvider;

import styled from 'styled-components';
import { transparentize } from 'polished';

import { FormControlLabel } from '@material-ui/core';

const CheckboxContainer = styled(FormControlLabel)`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 3rem;

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: ${(props) => props.theme.cores.primaria};
  }
  .MuiCheckbox-colorPrimary.Mui-checked:hover {
    background-color: ${(props) => transparentize(0.9, props.theme.cores.primaria)};
  }
  svg {
    width: 3rem;
    height: 3rem;
  }
`;

export default CheckboxContainer;

import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import MuiButton from '../MuiButton';
import { Title } from '../../styles/global';

import NoMatchContainer from './styles';

function NoMatchRouteContent() {
  const history = useHistory();

  return (
    <NoMatchContainer>
      <Title secondary="true">Oopps... nenhuma página encontrada com essa rota</Title>
      <MuiButton type="button" onClick={() => history.push('/')}>
        clique aqui para retornar ao app.
      </MuiButton>
    </NoMatchContainer>
  );
}

export default memo(NoMatchRouteContent);

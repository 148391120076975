import React, { useState, useEffect, memo } from 'react';

import AutocompleteStyled, { StyledInput } from './styles';

interface AutoCompleteProps {
  valorInicial: string,
  opcoes: any,
  label: string,
  opcoesLabel: any,
  requisicao: any,
  alteraValores: any,
  manipulaInput?: (value: string) => void,
  validacao?: boolean,
}

const MuiInputAutocomplete = ({
  valorInicial,
  opcoes,
  label,
  opcoesLabel,
  requisicao,
  alteraValores,
  manipulaInput,
  validacao = false,
}: AutoCompleteProps) => {
  const [inputDigitado, setInputDigitado] = useState(valorInicial);

  useEffect(() => { setInputDigitado(valorInicial); }, [valorInicial]);

  return (
    <AutocompleteStyled
      freeSolo
      clearOnEscape
      disableClearable
      options={opcoes}
      getOptionLabel={opcoesLabel}
      onChange={alteraValores}
      onInputChange={(event, value) => {
        setInputDigitado(value);
        if (manipulaInput) manipulaInput(value);
        requisicao(value);
      }}
      style={{ position: 'relative' }}
      renderInput={(params) => (
        <>
          <StyledInput
            {...params}
            fullWidth
            label={label}
            autoComplete="off"
            error={!validacao}
            inputProps={{ ...params.inputProps, value: (inputDigitado || '') }}
          />
        </>
      )}
    />
  );
};

export default memo(MuiInputAutocomplete);

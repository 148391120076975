import React, { memo, useMemo } from 'react';

import { IStateBreadcrumbs } from '../../contexts/AppContext';

import BackButton from './components/BackButton';

import HeaderContainer, {
  HeaderWrapper,
  HeaderTitle,
  HeaderText,
  Breadcrumb,
} from './styles';

interface HeaderProps {
  titulo: string;
  breadcrumbs: IStateBreadcrumbs;
  centralizado?: 'true';
  goBack: string;
}

function Header({
  titulo,
  breadcrumbs,
  centralizado,
  goBack,
}: HeaderProps) {
  const {
    textoMeioDePagamento,
    textoDiaDoVencimento,
    textoValorDaContribuicao,
  } = breadcrumbs;

  const renderMeioPagamento = useMemo(() => (
    <Breadcrumb>{textoMeioDePagamento}</Breadcrumb>
  ), [textoMeioDePagamento]);

  const renderDiaVencimento = useMemo(() => (
    <Breadcrumb>
      {textoDiaDoVencimento}
    </Breadcrumb>
  ), [textoDiaDoVencimento]);

  const renderValorContribuicao = useMemo(() => (
    <Breadcrumb>{textoValorDaContribuicao}</Breadcrumb>
  ), [textoValorDaContribuicao]);

  return (
    <HeaderContainer>
      <HeaderWrapper centralizado={centralizado}>
        <BackButton url={goBack} />
        <HeaderTitle
          fontcolor="#FFF"
          size={centralizado ? '3.2rem' : '3rem'}
          centralizado={centralizado}
        >
          {titulo}
        </HeaderTitle>
        {centralizado
          && (
            <HeaderText fontcolor="#FFF" size="2rem" opacity="0.8">
              selecione uma para continuar:
            </HeaderText>
          )}
        {textoMeioDePagamento && renderMeioPagamento}
        {textoDiaDoVencimento && renderDiaVencimento}
        {textoValorDaContribuicao && renderValorContribuicao}
      </HeaderWrapper>
    </HeaderContainer>
  );
}

export default memo(Header);

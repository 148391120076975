import {
  useReducer,
  useEffect,
  Reducer,
  ReducerState,
} from 'react';

// TODO: Tipar a resposta
type Resposta = [
  any,
  any,
];

export default function useReducerPersiste<R extends Reducer<any, any>, I>(
  key: string,
  reducer: R,
  initialArg: I & ReducerState<R>,
): Resposta {
  const [state, dispatch] = useReducer(
    reducer,
    initialArg,
    (initialState) => {
      const valorArmazenado = localStorage.getItem(key);
      const valor = valorArmazenado ? JSON.parse(valorArmazenado) : initialState;

      return { ...initialState, ...valor };
    },
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, dispatch];
}

import React, {
  useState,
  useCallback,
  memo,
  useEffect,
} from 'react';

import MuiFormControl, { SelectStyled, MenuStyled } from './styles';

interface SelectProps {
  items: number[];
  initialValue?: string | number | null;
  onChange: (value: string) => void;
}

function MuiSelect({ items, onChange, initialValue }: SelectProps) {
  const [selecionado, setSelecionado] = useState(initialValue);

  const manipulaSelect = useCallback((valor) => {
    setSelecionado(valor);
    onChange(valor);
  }, []);

  useEffect(() => {
    manipulaSelect(initialValue);
  }, [initialValue]);

  return (
    <MuiFormControl>
      <SelectStyled
        value={selecionado}
        onChange={(e) => manipulaSelect(e.target.value)}
      >
        {items.map((item) => (
          <MenuStyled key={`id-${item}`} value={item}>{item}</MenuStyled>
        ))}
      </SelectStyled>
    </MuiFormControl>
  );
}

export default memo(MuiSelect);

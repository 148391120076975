import styled from 'styled-components';

import LinearProgress from '@material-ui/core/LinearProgress';
import { Text } from '../../styles/global';

export const Stage = styled(Text)`
  && {
    width: initial;
    position: absolute;
    left: 50%;

    transform: translateX(-50%);
  }
`;

export const MuiLinearProgress = styled(LinearProgress)`
  && {
    height: 12px;
    width: 100%;

    border-radius: 100px;
    background-color: transparent;

    transition: 200ms linear;

    .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary {
      background-color: ${(props) => (props.theme.cores.primaria)};
      box-shadow: var(--box-shadow);

      border-radius: 100px;
    }
  }
`;

const ProgressBarContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;

  background: linear-gradient(180deg, #000000, #4A3801);
`;

export default ProgressBarContainer;

import styled from 'styled-components';

import { FormGroup, Radio } from '@material-ui/core';

export const RadioButton = styled(Radio)`
  && {
    &.MuiRadio-root {
      color: #000;
      &:hover {
        background-color: rgba(0,0,0,.1);
      }
    }

    &.Mui-checked {
      color: #000;
    }


    .MuiSvgIcon-root {
      width: 3rem;
      height: 3rem;
    }
    .PrivateRadioButtonIcon-root-5.PrivateRadioButtonIcon-checked-7 .PrivateRadioButtonIcon-layer-6 {
      transform: scale(1.2);
    }
  }
`;

const FormGroupContainer = styled(FormGroup)`
  width: 100%;

  margin: var(--espacamento-xlg) 0;

  label {
    margin: 0 auto;
    min-width: 200px;
  }

  hr {
    margin: var(--espacamento-sm) 0;
  }
`;

export default FormGroupContainer;

import React, { memo } from 'react';

import SelectButton from '../../../../../../components/SelectButton';
import { Text } from '../../../../../../styles/global';

import Container from './styles';

type ValuesType = {
  items: number[];
  onSelect: (item: number) => void;
}

function ValueButtonToContribute({ items, onSelect }: ValuesType) {
  return (
    <Container>
      {items.map((item) => (
        <SelectButton
          key={item}
          onClick={() => onSelect(item)}
        >
          <>
            <Text size="3rem" fontcolor="#444444" weight="600">
              {item}<span>,00</span>
            </Text>
          </>
        </SelectButton>
      ))}
      <SelectButton onClick={() => onSelect(0)}>
        <Text size="3rem" fontcolor="#444444" weight="600">
          Outro Valor
        </Text>
      </SelectButton>
    </Container>
  );
}

export default memo(ValueButtonToContribute);

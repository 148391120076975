import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  memo,
  useMemo,
} from 'react';

import InputMask from 'react-input-mask';

import { AppContext } from '../../../../contexts/AppContext';
import { DadosCadastraisContext } from '../../../../contexts/DadosCadastraisContext';

import { get } from '../../../../services/api';

import MuiAutocomplete from '../../../../components/MuiAutocomplete';
import MuiButton from '../../../../components/MuiButton';
import Loading from '../../../../components/Loading';

import { InputSuffix } from '../../../../styles/global';
import useRouter from '../../../../utils/hooks/useRouter';

import InputStyled, { MenuStyled } from './styles';

interface ICamposEndereco {
  [key: string]: any
}

let tempo: any;
let ultimoEstado: string;
let cepViaLogradouro: boolean = true;

function Endereco() {
  const router = useRouter();
  const { appState, appDispatch } = useContext(AppContext);
  const { dadosCadastrais, dadosCadastraisDispatch } = useContext(DadosCadastraisContext);
  const [valido, setValido] = useState<ICamposEndereco>({
    cep: true,
    estado: true,
    idCidade: true,
    cidade: true,
    bairro: true,
    logradouro: true,
    numero: true,
  });
  const [etapaValida, setEtapaValida] = useState<boolean>(false);
  const [cidades, setCidades] = useState([]);
  const [bairros, setBairros] = useState([]);
  const [logradouros, setLogradouros] = useState([]);

  const { endereco } = dadosCadastrais;

  const regex: ICamposEndereco = {
    cep: /^\d{5}-\d{3}$/,
    estado: /^[A-Z]{2}$/,
    idCidade: /^\d{1,}$/,
    cidade: /^[.a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ() \-'`]{2,}$/,
    bairro: /^[.a-zA-Z0-9áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ() \-'`]{2,}$/,
    logradouro: /^[.a-zA-Z0-9áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ() \-'`]{2,}$/,
    numero: /^[a-zA-Z0-9 ]{1,}$/,
  };

  /**
   * Ao carregar componente atualiza url anterior, etapa Atual e evita busca do cep
   */
  useEffect(() => {
    appDispatch({ type: 'ALTERA_URL_ANTERIOR', payload: '/formulario/4/dados-pessoais/email' });
    appDispatch({ type: 'ALTERA_ETAPA_ATUAL', payload: 4 });
    cepViaLogradouro = true;
    setEtapaValida(false);

    return () => {
      cepViaLogradouro = true;
    };
  }, []);

  /**
   * Valida se o campo digitado está correto
   * @param {string} valor - Valor digitado pelo usuario
   * @param {string} campo - Campo do input digitado pelo usuario
   * @return {boolean} Se é valido ou não o nome digitado
  */
  const validaCampo = useCallback((valor: string, campo: string): boolean => {
    if (!(campo in valido)) {
      return false;
    }

    const resultado = (regex[campo] || /./).test(valor);
    valido[campo] = (valor === null ? null : resultado);

    setValido(valido);

    return resultado;
  }, []);

  /**
   * Monitora a alteração no estado de endereço, quando alterar valida os campos
   */
  useEffect(() => {
    Object.entries(endereco).map(([attr, valor]) => validaCampo(valor, attr));

    const atributosObrigatoriosValidos = (
      (Object.values(valido).indexOf(false) === -1)
      && (Object.values(valido).indexOf(null) === -1)
    );
    setEtapaValida(atributosObrigatoriosValidos);
  }, [endereco]);

  /**
   * Monitora estado de etapa valida e habilita/desabilita botao de continuar
   * conforme o estado da etapa
   */
  useEffect(() => {
    if (etapaValida) {
      appDispatch({ type: 'MANIPULA_DISABLED', payload: false });
    } else {
      appDispatch({ type: 'MANIPULA_DISABLED', payload: true });
    }
  }, [etapaValida]);

  /**
   * Retorna os estados com sigla e nome
   */
  const obtemEstados = useMemo(() => ([
    { sigla: 'AC', nome: 'Acre' },
    { sigla: 'AL', nome: 'Alagoas' },
    { sigla: 'AP', nome: 'Amapá' },
    { sigla: 'AM', nome: 'Amazonas' },
    { sigla: 'BA', nome: 'Bahia' },
    { sigla: 'CE', nome: 'Ceará' },
    { sigla: 'DF', nome: 'Distrito Federal' },
    { sigla: 'ES', nome: 'Espírito Santo' },
    { sigla: 'GO', nome: 'Goiás' },
    { sigla: 'MA', nome: 'Maranhão' },
    { sigla: 'MT', nome: 'Mato Grosso' },
    { sigla: 'MS', nome: 'Mato Grosso do Sul' },
    { sigla: 'MG', nome: 'Minas Gerais' },
    { sigla: 'PA', nome: 'Pará' },
    { sigla: 'PB', nome: 'Paraíba' },
    { sigla: 'PR', nome: 'Paraná' },
    { sigla: 'PE', nome: 'Pernambuco' },
    { sigla: 'PI', nome: 'Piauí' },
    { sigla: 'RJ', nome: 'Rio de Janeiro' },
    { sigla: 'RN', nome: 'Rio Grande do Norte' },
    { sigla: 'RS', nome: 'Rio Grande do Sul' },
    { sigla: 'RO', nome: 'Rondônia' },
    { sigla: 'RR', nome: 'Roraima' },
    { sigla: 'SC', nome: 'Santa Catarina' },
    { sigla: 'SP', nome: 'São Paulo' },
    { sigla: 'SE', nome: 'Sergipe' },
    { sigla: 'TO', nome: 'Tocantins' },
  ]), []);

  /**
   * Chamada na api-endereços pesquisando pelo endereço conforme CEP informado no campo
   * Atualiza estado de endereço
   * @param {string} valorCep - Cep informado pelo usuario
   */
  const pesquisaEnderecoPorCep = useCallback(async (valorCep: string) => {
    if (valido.cep === false || cepViaLogradouro) {
      setTimeout(() => {
        cepViaLogradouro = false;
      }, 800);
      return;
    }
    appDispatch({ type: 'MANIPULA_CARREGANDO', payload: true });

    try {
      const retorno = await get('api-enderecos', `/buscar-localidade?cep=${valorCep}`, {});
      if (retorno?.status === 200) {
        if (retorno.data.length === 0) {
          return;
        }
        const {
          cep,
          estado,
          cidade,
          bairro,
          logradouro,
        } = retorno.data;

        ultimoEstado = estado;
        dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_CEP', cep: cep.replace('.', '') });
        dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_ESTADO', estado });
        dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_CIDADE', cidade, idCidade: retorno.data.cidade_id });

        if (bairro === 'Não Informado' || logradouro === ' Não Informado') {
          dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_BAIRRO', bairro: '', idBairro: retorno.data.bairro_id });
          dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_LOGRADOURO', logradouro: '', idLogradouro: retorno.data.logradouro_id });
        } else {
          dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_BAIRRO', bairro, idBairro: retorno.data.bairro_id });
          dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_LOGRADOURO', logradouro, idLogradouro: retorno.data.logradouro_id });
        }

        dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_CIDADE', cidade, idCidade: retorno.data.cidade_id });
        dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_NUMERO', numero: '' });
        dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_COMPLEMENTO', complemento: '' });
      } else {
        valido.cep = false;
        setValido(valido);
      }
    } catch (err) {
      console.log(err);
    } finally {
      appDispatch({ type: 'MANIPULA_CARREGANDO', payload: false });
    }
  }, []);

  /**
   * Limpa campos do formulário
   * @param {string[]} campos - Campos a serem limpados
   */
  const limpaCampos = useCallback((campos: string[]) => {
    campos.map((campo) => {
      if (campo === 'cep') dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_CEP', cep: '' });
      if (campo === 'estado') {
        ultimoEstado = '';
        dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_ESTADO', estado: '' });
      }
      if (campo === 'cidade') dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_CIDADE', cidade: '', idCidade: 0 });
      if (campo === 'bairro') dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_BAIRRO', bairro: '', idBairro: 0 });
      if (campo === 'logradouro') dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_LOGRADOURO', logradouro: '', idLogradouro: 0 });
      if (campo === 'numero') dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_NUMERO', numero: '' });
      if (campo === 'complemento') dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_COMPLEMENTO', complemento: '' });
      return true;
    });
  }, []);

  /**
   * Ao digitar valida o valor digitado pelo usuario,
   * se for valido salva o valor no estado
   * @param {string} valor - Valor digitado pelo usuario
   * @param {string} campo - Campo a ser alterado
   */
  const manipulaInput = useCallback((valor: any, campo: string) => {
    const tempEndereco: any = endereco;
    tempEndereco[campo] = valor;

    const campoValido = validaCampo(valor, campo);

    switch (campo) {
      case 'cep':
        if (valor.length > 9 || cepViaLogradouro) {
          return;
        }

        limpaCampos([
          'estado',
          'idCidade',
          'cidade',
          'idBairro',
          'bairro',
          'idLogradouro',
          'logradouro',
          'numero',
          'complemento',
        ]);

        setCidades([]);
        setBairros([]);
        setLogradouros([]);

        if (valor === '') {
          dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_CEP', cep: valor });
        }
        break;

      case 'estado':

        if (ultimoEstado === valor || cepViaLogradouro) {
          return;
        }

        limpaCampos([
          'cidade',
          'idCidade',
          'idBairro',
          'bairro',
          'idLogradouro',
          'logradouro',
          'numero',
          'complemento',
          'idCidade',
        ]);

        setCidades([]);
        setBairros([]);
        setLogradouros([]);

        if (campoValido) {
          dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_ESTADO', estado: valor });
          ultimoEstado = valor;
        }
        break;

      case 'numero':
        if (campoValido || valor === '') {
          dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_NUMERO', numero: valor });
        }
        break;

      case 'complemento':
        dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_COMPLEMENTO', complemento: valor });

        break;

      default:
        break;
    }
  }, [endereco]);

  /**
   * Ao usuário selecionar um item do Mui Autocomplete
   * @param {event} event - não é utilizado nessa função
   * @param {{ idLogradouro: number, logradouro: string}} value - Objeto com Id e valor selecionado
   */
  const manipulaLogradouro = useCallback((event, value) => {
    const idLogradouro = value?.idLogradouro ?? null;
    const logradouro = value?.logradouro ?? '';
    const cep = value?.cep ?? '';
    cepViaLogradouro = true;

    const logradouroValido = validaCampo(logradouro, 'logradouro');
    const cepValido = validaCampo(cep, 'cep');

    if (logradouroValido && cepValido) {
      dadosCadastraisDispatch({
        type: 'ALTERA_ENDERECO_LOGRADOURO',
        logradouro,
        idLogradouro,
      });
      dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_CEP', cep });
    }
  }, []);

  /**
   * Ao usuário selecionar um item do Mui Autocomplete
   * @param {event} event - não é utilizado nessa função
   * @param {{ idBairro: number, bairro: string}} value - Objeto com Id e valor selecionado
   */
  const manipulaBairro = useCallback((event, value) => {
    const idBairro = value?.idBairro ?? null;
    const bairro = value?.bairro ?? '';

    limpaCampos([
      'idLogradouro',
      'logradouro',
      'numero',
      'complemento',
    ]);
    // limpa autocompletes
    setLogradouros([]);

    const bairroValido = validaCampo(bairro, 'cidade');

    if (bairroValido) {
      dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_BAIRRO', bairro, idBairro });
    }
  }, []);

  /**
   * Ao usuário selecionar um item do Mui Autocomplete
   * @param {event} event - não é utilizado nessa função
   * @param {{ idCidade: number, cidade: string}} value - Objeto com Id e valor selecionado
   */
  const manipulaCidade = useCallback((event, value) => {
    const idCidade = value?.idCidade ?? null;
    const cidade = value?.cidade ?? '';

    const cidadeValida = validaCampo(cidade, 'cidade');
    const idCidadeValida = validaCampo(idCidade, 'idCidade');

    limpaCampos([
      'idBairro',
      'bairro',
      'idLogradouro',
      'logradouro',
      'numero',
      'complemento',
    ]);
    // limpa autocompletes
    setBairros([]);
    setLogradouros([]);

    if (cidadeValida && idCidadeValida) {
      dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_CIDADE', cidade, idCidade });
    } else {
      dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_CIDADE', cidade: value.cidade, idCidade: 0 });
    }
  }, []);

  /**
   * Monitora estado "estado", caso for alterado preenche
   * variavel "ultimoEstado" com o valor do "estado" para evitar
   * chamadas desnecessárias
   */
  useEffect(() => {
    if (endereco.estado?.length) {
      ultimoEstado = endereco.estado;
    }
  }, [endereco.estado]);

  /**
   * Busca cidades a partir do Estado
   * @param {string} cidade - nome da cidade
   */
  const pesquisaCidades = useCallback(async (cidade: string) => {
    if (valido.estado === null || valido.estado === false) {
      return;
    }

    limpaCampos([
      'idBairro',
      'bairro',
      'idLogradouro',
      'logradouro',
      'numero',
      'complemento',
    ]);

    clearTimeout(tempo);
    appDispatch({ type: 'MANIPULA_CARREGANDO', payload: true });

    tempo = setTimeout(async () => {
      const retorno = await get('api-enderecos', `/cidades?uf=${endereco.estado}&nome=${cidade}`, {});
      if (retorno?.status === 200) {
        const opcoes = retorno.data.map((item: any) => ({
          idCidade: item.idcidade,
          cidade: item.cidade,
        }));
        setCidades(opcoes);

        if (opcoes.length === 1 && opcoes[0].cidade === cidade) {
          const cidadeSelecionado = {
            cidade: opcoes[0].cidade.trim(),
            idCidade: opcoes[0].idCidade,
          };
          manipulaCidade('', cidadeSelecionado);
        } else {
          const cidadeTemp = {
            idCidade: 0,
            cidade,
          };
          manipulaCidade('', cidadeTemp);
        }
        appDispatch({ type: 'MANIPULA_CARREGANDO', payload: false });
      }
    }, 600);
  }, [endereco.estado, endereco.cidade, cidades]);

  /**
   * Busca bairros a partir do idCidade
   * @param {string} bairro - nome do bairro
   */
  const pesquisaBairros = useCallback(async (bairro: string) => {
    if (endereco.idCidade === null) {
      return;
    }

    limpaCampos([
      'idLogradouro',
      'logradouro',
      'numero',
      'complemento',
    ]);

    clearTimeout(tempo);
    appDispatch({ type: 'MANIPULA_CARREGANDO', payload: true });

    tempo = setTimeout(async () => {
      const retorno = await get(
        'api-enderecos',
        `/bairros?id_cidade=${endereco.idCidade}&nome=${bairro}`,
        {},
      );
      if (retorno?.status === 200) {
        const opcoes = retorno.data.map((item: any) => ({
          idBairro: item.idbairro,
          bairro: item.bairro,
        }));
        setBairros(opcoes);

        if (opcoes.length === 1 && opcoes[0].bairro === bairro) {
          const bairroSelecionado = {
            bairro: opcoes[0].bairro.trim(),
            idBairro: opcoes[0].idBairro,
          };
          manipulaBairro(null, bairroSelecionado);
        }
      }
    }, 600);

    appDispatch({ type: 'MANIPULA_CARREGANDO', payload: false });
  }, [endereco.idCidade, endereco.bairro]);

  /**
   * Busca logradouros a partir do idCidade e idBairro
   * @param {string} logradouro - nome do logradouro
   */
  const pesquisaLogradouros = useCallback(async (logradouro: string) => {
    if (endereco.idCidade === null || endereco.idBairro === null) {
      return;
    }

    limpaCampos([
      'numero',
      'complemento',
    ]);

    clearTimeout(tempo);
    appDispatch({ type: 'MANIPULA_CARREGANDO', payload: true });

    tempo = setTimeout(async () => {
      const retorno = await get(
        'api-enderecos',
        `/logradouros?id_cidade=${endereco.idCidade}&id_bairro=${endereco.idBairro}&nome=${logradouro}`,
        {},
      );
      if (retorno?.status === 200) {
        const opcoes = retorno.data.map((item: any) => ({
          idLogradouro: item.idlogradouro,
          logradouro: item.logradouro,
          faixa: item.faixa,
          cep: (item.cep !== null ? item.cep.replace('.', '') : item.cep),
        }));

        setLogradouros(opcoes);

        if (opcoes.length === 1 && opcoes[0].logradouro === logradouro) {
          const logradouroSelecionado = {
            logradouro: opcoes[0].logradouro.trim(),
            idLogradouro: opcoes[0].idLogradouro,
            cep: opcoes[0].cep,
          };

          manipulaLogradouro('', logradouroSelecionado);
        }
      }
      appDispatch({ type: 'MANIPULA_CARREGANDO', payload: false });
    }, 600);
  }, [endereco.idCidade, endereco.idBairro, endereco.logradouro]);

  return (
    <>
      {appState.carregando && <Loading />}
      <InputMask
        mask="99999-999"
        maskPlaceholder=" "
        value={endereco.cep || ''}
        onChange={(event) => {
          manipulaInput(event.target.value, 'cep');
          pesquisaEnderecoPorCep(event.target.value);
        }}
        style={{ width: '100%' }}
      >
        {(inputProps: any) => (
          <InputStyled
            id="cep"
            name="cep"
            label="Informe seu CEP"
            type="tel"
            {...inputProps}
          />
        )}
      </InputMask>

      <InputStyled
        fullWidth
        select
        label="Estado"
        value={endereco.estado || ''}
        onChange={(event) => {
          cepViaLogradouro = false;
          manipulaInput(event.target.value, 'estado');
        }}
      >
        {obtemEstados.map((opcao) => (
          <MenuStyled key={opcao.sigla} value={opcao.sigla}>
            {opcao.nome}
          </MenuStyled>
        ))}
      </InputStyled>

      <MuiAutocomplete
        valorInicial={endereco.cidade}
        label="Cidade"
        opcoes={cidades}
        opcoesLabel={(option: any) => option.cidade || ''}
        alteraValores={manipulaCidade}
        requisicao={pesquisaCidades}
        validacao
      />

      <MuiAutocomplete
        valorInicial={endereco.bairro}
        label="Bairro"
        opcoesLabel={(option: any) => option.bairro || ''}
        opcoes={bairros}
        alteraValores={manipulaBairro}
        requisicao={pesquisaBairros}
        manipulaInput={(bairro: string) => {
          const tempValuePrefixo = bairro.substr(0, bairro.indexOf(' ')).toLowerCase();
          const tempValueSemPrefixo = bairro.substr(bairro.indexOf(' '));

          // Corrige prefixo
          const regrasCorrecaoPrefixo = [
            { regex: /^vl|^vl./, replace: 'Vila' },
            { regex: /^jd|^jd./, replace: 'Jardim' },
            { regex: /^pq|^pq./, replace: 'Parque' },
            { regex: /^lot|^lot./, replace: 'Loteamento' },
            { regex: /^conj|^conj./, replace: 'Conjunto' },
          ];

          const correcaoPrefixo = (
            tempValuePrefixo === 'vl'
            || tempValuePrefixo === 'vl.'
            || tempValuePrefixo === 'jd'
            || tempValuePrefixo === 'jd.'
            || tempValuePrefixo === 'pq'
            || tempValuePrefixo === 'pq.'
            || tempValuePrefixo === 'lot'
            || tempValuePrefixo === 'lot.'
            || tempValuePrefixo === 'conj'
            || tempValuePrefixo === 'conj.'
          );

          regrasCorrecaoPrefixo.forEach((regra) => {
            if (regra.regex.test(tempValuePrefixo) && correcaoPrefixo) {
              // eslint-disable-next-line no-param-reassign
              bairro = regra.replace + tempValueSemPrefixo;
            }
          });

          dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_BAIRRO', bairro, idBairro: 0 });
        }}
        validacao
      />

      <MuiAutocomplete
        valorInicial={endereco.logradouro}
        label="Logradouro"
        opcoesLabel={(option: any) => {
          let { logradouro } = option;
          logradouro += (option.faixa !== null ? ` ${option.faixa}` : '');
          return logradouro;
        }}
        opcoes={logradouros}
        requisicao={pesquisaLogradouros}
        manipulaInput={(logradouro: string) => {
          const tempValuePrefixo = logradouro.substr(0, logradouro.indexOf(' ')).toLowerCase();
          const tempValueSemPrefixo = logradouro.substr(logradouro.indexOf(' '));

          // Corrige prefixo
          const regrasCorrecaoPrefixo = [
            { regex: /^r|^r.+i/, replace: 'Rua' },
            { regex: /^al|^al./, replace: 'Alameda' },
            { regex: /^av|^av./, replace: 'Avenida' },
          ];
          regrasCorrecaoPrefixo.forEach((regra) => {
            if (regra.regex.test(tempValuePrefixo) && (tempValuePrefixo === 'r' || tempValuePrefixo === 'r.')) {
              logradouro = regra.replace + tempValueSemPrefixo;
            }
          });

          dadosCadastraisDispatch({ type: 'ALTERA_ENDERECO_LOGRADOURO', logradouro, idLogradouro: 0 });
        }}
        validacao
        alteraValores={manipulaLogradouro}
      />

      <InputStyled
        fullWidth
        name="numero"
        label="Número"
        value={endereco.numero || ''}
        onChange={(event) => manipulaInput(event.target.value, 'numero')}
      />

      <InputStyled
        fullWidth
        name="complemento"
        label="Complemento"
        value={endereco.complemento || ''}
        onChange={(event) => manipulaInput(event.target.value, 'complemento')}
        InputProps={{
          endAdornment: (
            <InputSuffix>
              (opcional)
            </InputSuffix>
          ),
        }}
        style={{ marginBottom: '5rem' }}
      />
      <MuiButton
        primary="true"
        fullWidth
        disabled={appState.disabled}
        onClick={() => router.push('/formulario/5/termo')}
      >
        Continuar
      </MuiButton>
    </>
  );
}

export default memo(Endereco);

import styled from 'styled-components';

import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

export const StyledInput = styled(TextField)`
  && {
    margin: var(--espacamento-xsm) 0;
    label,
    > div {
      font-size: 2.3rem;
      text-align: left;

      svg {
        width: 4rem;
        height: 4rem;

        top: calc(50% - 16px);
      }
    }

    .MuiFormLabel-root.Mui-focused {
      color: ${(props) => (props.error ? '#f44336' : props.theme.cores.primaria)}
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid;
      border-color: ${(props) => (props.error ? '#f44336' : props.theme.cores.secundaria)};
    }
    .MuiInput-underline.Mui-disabled:before {
      border-bottom: 1px dotted #000000;
    }

    .MuiInput-underline:after {
      border-bottom: 3px solid;
      border-color: ${(props) => (props.error ? '#f44336' : props.theme.cores.primaria)};
    }
    .MuiInput-underline.Mui-disabled:after {
      border-bottom: 1px dotted #000000;
    }

    .MuiFormHelperText-root {
      color: #f44336;
      font-size: 1.75rem;
    }
  }
`;

const AutocompleteStyled = styled(Autocomplete)`
  && {
    width: 100%;
  }
`;

export default AutocompleteStyled;

import styled from 'styled-components';

import { Alert } from '@material-ui/lab';

export const AlertStyled = styled(Alert)`
  && {
    font-size: 2rem;
  }
`;

const ButtonGroup = styled.div`
  width:  100%;
  button:last-child {
    margin-top: 3rem;
  }
`;

export default ButtonGroup;

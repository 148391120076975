import { get } from '../services/api';

/**
 * Recebe idTermoTipo (e Schema) e retorna o Termo
 * @param {number} idTermoTipo
 * @param {string} schema
 * @returns objeto com o idTermo e texto
 */
export default async function getTermo(idTermoTipo: number, schema: string = 'libcom') {
  try {
    const retorno = await get('api-pessoas', `/pessoas/termos?tipo=${idTermoTipo}&atual=1`, { schema });
    if (retorno) {
      return retorno.data[0];
    }
  } catch (error) {
    console.log(error);
    return [];
  }

  return true;
}

import FormaDeContribuicao from './FormaDeContribuicao';
import MeioDePagamento from './MeioDePagamento';
import EscolhaDeValores from './EscolhaDeValores';
import MelhorDiaParaContribuir from './MelhorDiaParaContribuir';
import MesDaContribuicao from './MesDaContribuicao';
import OutroValor from './OutroValor';

const rotasContribuicao = [
  {
    path: '/formulario/1/contribuicao/forma-de-contribuicao',
    component: FormaDeContribuicao,
  },
  {
    path: '/formulario/2/contribuicao/meio-de-pagamento',
    component: MeioDePagamento,
  },
  {
    path: '/formulario/2/contribuicao/melhor-dia',
    component: MelhorDiaParaContribuir,
  },
  {
    path: '/formulario/2/contribuicao/mes-escolhido',
    component: MesDaContribuicao,
  },
  {
    path: '/formulario/3/contribuicao/informa-valor',
    component: EscolhaDeValores,
  },
  {
    path: '/formulario/3/contribuicao/outro-valor',
    component: OutroValor,
  },
];

export default rotasContribuicao;

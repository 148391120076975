import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

import ArrowBackButton from './styles';

interface BackButtonProps {
  url: string;
}

function BackButton({ url }: BackButtonProps) {
  return (
    <Link to={url}>
      <ArrowBackButton>
        <ArrowBackIcon />
      </ArrowBackButton>
    </Link>
  );
}

export default memo(BackButton);

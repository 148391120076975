import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import AppContextProvider from './contexts/AppContext';
import ContribuicaoContextProvider from './contexts/ContribuicaoContext';
import DadosCadastraisContextProvider from './contexts/DadosCadastraisContext';
import BoletoContextProvider from './contexts/BoletoContext';
import libcom from './styles/themes/libcom';
import GlobalStyle from './styles/global';
import Routes from './routes';

ReactDOM.render(
  <ThemeProvider theme={libcom}>
    <GlobalStyle />
    <AppContextProvider>
      <ContribuicaoContextProvider>
        <DadosCadastraisContextProvider>
          <BoletoContextProvider>
            <Routes />
          </BoletoContextProvider>
        </DadosCadastraisContextProvider>
      </ContribuicaoContextProvider>
    </AppContextProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);

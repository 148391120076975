import React, { ReactNode } from 'react';
import {
  BrowserRouter as Router, Switch, Route, Redirect, RouteComponentProps,
} from 'react-router-dom';

import Home from './pages/Home';
import Form from './pages/Form';
import ContribuicaoConcluida from './pages/ContribuicaoConcluida';

import NoMatchRouteContent from './components/NoMatchRouteContent';

interface PrivateRouteProps {
  component: React.FC;
  path: string;
  exact: boolean;
}

function PrivateRoute({ path, exact, component }: PrivateRouteProps) {
  const autorizado = true;

  return autorizado ? (
    <Route path={path} exact={exact} component={component} />
  ) : (<Redirect to="/" />);
}

interface RouteWithSubRoutesProps {
  component: React.ComponentType<RouteComponentProps<any>>;
  path: string;
  key: string;
}

export function RouteWithSubRoutes({
  path,
  component: Component,
  key,
}: RouteWithSubRoutesProps) {
  return (
    <Route
      key={key}
      path={path}
      render={(props: RouteComponentProps): ReactNode => (
        <Component {...props} />
      )}
    />
  );
}

export function NoMatchRoute() {
  return (
    <NoMatchRouteContent />
  );
}

function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/formulario" component={Form} />
        <Route exact path="/contribuicao-concluida" component={ContribuicaoConcluida} />
        <Route path="*" component={NoMatchRoute} />
      </Switch>
    </Router>
  );
}

export default Routes;

import styled from 'styled-components';

const ConteudoLGPD = styled.div`
  && {
  color: #444;

  .MuiTypography-body2 {
    font-size: 2rem;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 1rem;
  }
  .MuiTypography-h6 {
    font-size: 2.5rem;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;

    padding: 0;
    background: transparent;
  }

  div ol {
  padding-left: 24px;

    li {
      font-size: 1.9rem;
      margin-bottom: 8px;
      text-align: left;

      &::marker {
        font-weight: bold;
      }
    }
  }

  strong {
    text-decoration: underline;
  }
  }
`;

export default ConteudoLGPD;

import React, {
  createContext,
  Dispatch,
} from 'react';

import useReducerPersiste from '../utils/hooks/useReducerPersiste';
import { WithChildren } from '../interfaces';

interface IActionIniciaContribuicao {
  type: 'INICIA_CONTRIBUICAO',
  payload: boolean
}
interface IActionCarregando {
  type: 'MANIPULA_CARREGANDO',
  payload: boolean
}
interface IActionDisabled {
  type: 'MANIPULA_DISABLED',
  payload: boolean
}
interface IActionEtapaAtual {
  type: 'ALTERA_ETAPA_ATUAL',
  payload: number
}
interface IActionUrlAnterior {
  type: 'ALTERA_URL_ANTERIOR',
  payload: string
}
interface IActionTitulo {
  type: 'ALTERA_TITULO',
  payload: string
}
interface IActionBreadcrumbs {
  type: 'ALTERA_BREADCRUMBS',
  payload: IStateBreadcrumbs
}
interface IActionReset {
  type: 'RESET_DADOS'
}

type AppAction = IActionReset
  | IActionIniciaContribuicao
  | IActionDisabled
  | IActionEtapaAtual
  | IActionUrlAnterior
  | IActionCarregando
  | IActionTitulo
  | IActionBreadcrumbs;

interface AppState {
  contribuicaoIniciada: boolean,
  disabled: boolean,
  carregando: boolean,
  cabecalho: IStateCabecalho,
}
interface IStateCabecalho {
  titulo: string,
  breadcrumbs: IStateBreadcrumbs,
  urlAnterior: string,
  etapa: number
}
export interface IStateBreadcrumbs {
  textoMeioDePagamento: string,
  textoValorDaContribuicao: string,
  textoDiaDoVencimento: string,
  textoMesDeInicio: string
}

type AppContextData = {
  appState: AppState;
  appDispatch: Dispatch<AppAction>;
}
const valorInicialDoContexto: AppContextData = {
  appState: {
    contribuicaoIniciada: false,
    cabecalho: {
      titulo: '',
      breadcrumbs: {
        textoMeioDePagamento: '',
        textoValorDaContribuicao: '',
        textoDiaDoVencimento: '',
        textoMesDeInicio: '',
      },
      urlAnterior: '/',
      etapa: 0,
    },
    disabled: false,
    carregando: false,
  },
  appDispatch: (value) => {},
};

export const AppContext = createContext(valorInicialDoContexto);

const appReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case 'INICIA_CONTRIBUICAO':
      return {
        ...state,
        contribuicaoIniciada: action.payload,
      };

    case 'MANIPULA_DISABLED':
      return { ...state, disabled: action.payload };

    case 'MANIPULA_CARREGANDO':
      return { ...state, carregando: action.payload };

    case 'ALTERA_ETAPA_ATUAL':
      return {
        ...state,
        cabecalho: {
          ...state.cabecalho,
          etapa: action.payload,
        },
      };

    case 'ALTERA_URL_ANTERIOR':
      return {
        ...state,
        cabecalho: {
          ...state.cabecalho,
          urlAnterior: action.payload,
        },
      };

    case 'ALTERA_TITULO':
      return {
        ...state,
        cabecalho: {
          ...state.cabecalho,
          titulo: action.payload,
        },
      };

    case 'ALTERA_BREADCRUMBS':
      return {
        ...state,
        cabecalho: {
          ...state.cabecalho,
          breadcrumbs: action.payload,
        },
      };

    case 'RESET_DADOS':
      return valorInicialDoContexto.appState;

    default:
      return state;
  }
};

function AppContextProvider({ children }: WithChildren) {
  const [appState, appDispatch] = useReducerPersiste(
    'appState',
    appReducer,
    valorInicialDoContexto.appState,
  );

  return (
    <AppContext.Provider
      value={{
        appState,
        appDispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;
